body.pop-up-active {
  overflow: hidden;
}
.pop-ups-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}
.pop-ups {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  padding: 0 10px;
  &.is-active {
    opacity: 1;
		visibility: visible;
		overflow: scroll;
  }
  &--close {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    z-index: 20;
    &:hover {
      cursor: pointer;
      &:after {
        border-color: $hover-color;
      }
      &:before {
        border-color: $hover-color;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 47%;
      left: 35%;
      width: 13px;
      border-top: 1px solid #828282;
      transform: rotate(45deg);
    }
    &:before {
      content: "";
      position: absolute;
      top: 47%;
      left: 35%;
      width: 13px;
      border-top: 1px solid #828282;
      transform: rotate(-45deg);
    }
  }
  &__call-me {
    display: none;
    &.is-active {
      display: block;
    }
    &__wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 340px;
      height: 340px;
      margin: 0 auto;
      margin-top: 50px;
      background: #ffffff;
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 30px 0;
        max-width: 215px;
        border-top: 3px solid $hover-color;
        font-family: "Play";
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: $black;
      }

      &__name {
        display: flex;
        flex-direction: column;
        max-width: 258px;
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        background: #f8f8f8;
        label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #4f4f4f;
        }

        input {
          font-family: "Play";
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          background: #f8f8f8;
          border: none;
        }
      }

      &__tel {
        display: flex;
        flex-direction: column;
        max-width: 258px;
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        background: #f8f8f8;
        label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #4f4f4f;
        }

        input {
          font-family: "Play";
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          background: #f8f8f8;
          border: none;
        }
      }

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 258px;
        width: 100%;
        height: 55px;
        border: 1px solid #f2994a;
        font-family: "Play";
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        background-color: #fff;
        &:hover {
          cursor: pointer;
          background-color: $hover-color;
          color: #fff;
        }
      }
      &__status {
        visibility: hidden;
        margin-top: 20px;
        font-family: "Play";
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        &.is-active {
          visibility: visible;
        }
      }
    }
  }
  .pop-ups-leave-comment {
    display: none;
    &.is-active {
      display: block;
    }
    &__wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 500px;
      width: 100%;
      //   height: 870px;
      margin: 0 auto;
      margin-top: 20px;
      padding: 0 20px 15px 20px;
      background: #ffffff;
      @media screen and (max-width: 576px) {
        margin-top: 10px;
        padding: 0 15px 10px 15px;
      }
      &__tilte {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 20px 0;
        width: 100%;
        border-top: 3px solid $hover-color;
        font-family: "Play";
        font-weight: 700;
        font-size: 21px;
        line-height: 23px;
        color: $black;
        @media screen and (max-width: 576px) {
          padding: 10px 0;
          font-size: 18px;
        }
      }
      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #4f4f4f;
      }

      input {
        font-family: "Play";
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        background: #f8f8f8;
        border: none;
        @media screen and (max-width: 576px) {
          font-size: 14px;
        }
      }
      &__name {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__mail {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__tel {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__city {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__text {
        width: 100%;
        height: 100px;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
        border: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        resize: none;
        overflow: auto;
        @media screen and (max-width: 576px) {
          font-size: 14px;
          height: 50px;
        }
        &::placeholder {
          color: #4f4f4f;
        }
      }

      &__rating {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__text {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #4f4f4f;
        }

        &__star {
          .overall-rating {
            &__star {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;

              input[type="radio"] {
                display: none;
                z-index: 0;
              }

              input[type="radio"] ~ label {
                display: block;
                width: 27px;
                height: 25px;
                margin-right: 8px;
                z-index: 1;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url("./../img/icn-star-empty.svg");
                @media screen and (max-width: 576px) {
                  width: 20px;
                  height: 18px;
                  margin-right: 5px;
                }
              }

              input[type="radio"]:checked ~ label,
              input[type="radio"]:hover ~ label {
                background-image: url("./../img/icn-star-full.svg");
              }
            }
          }
        }
      }

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        border: 1px solid #f2994a;
        font-family: "Play";
        font-weight: 700;
        font-size: 20px;
        line-height: 19px;
        color: #fff;
        background-color: #fff;
        background-color: $hover-color;
        margin-top: 10px;
        &:hover {
          cursor: pointer;
          background-color: #e0893c;
        }
        @media screen and (max-width: 576px) {
          font-size: 16px;
          height: 35px;
        }
      }
      &__status {
        visibility: hidden;
        margin-top: 10px;
        font-family: "Play";
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        text-align: center;
        display: none;
        &.is-active {
          visibility: visible;
          display: block;
        }
        @media screen and (max-width: 576px) {
          font-size: 14px;
        }
      }
    }
  }
  &__get-discount {
    display: none;
    &.is-active {
      display: block;
    }
    &__wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
      background: #ffffff;
      .pop-ups--close {
        top: 17px;
        right: 17px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.28);
        @media screen and (max-width: 576px) {
          top: 10px;
          right: 10px;
        }
        &::after,
        &::before {
          top: 49%;
          left: 32%;
          border-top: 2px solid #828282;
        }
        &:hover {
          &:after {
            border-color: $normal-color;
          }
          &:before {
            border-color: $normal-color;
          }
        }
      }
      &__tilte {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;
        background-color: $hover-color;
        @media screen and (max-width: 576px) {
          height: 60px;
        }
        p {
          position: relative;
          z-index: 3;
          padding: 0;
          font-family: "Play";
          font-weight: 700;
          font-size: 21px;
          line-height: 23px;
          color: #fff;
        }
        img {
          position: absolute;
          width: 25%;
          bottom: 0;
          left: 10px;
          @media screen and (max-width: 576px) {
            width: 80px;
          }
        }
      }
      &--content {
        padding: 40px;
        width: 100%;
        background-image: url(./../img/bg-discount-pop-up.png);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        @media screen and (max-width: 576px) {
          padding: 20px;
        }
      }
      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #4f4f4f;
      }

      input {
        font-family: "Play";
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        background: #f8f8f8;
        border: none;
        @media screen and (max-width: 576px) {
          font-size: 14px;
        }
      }
      &__name {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__mail {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__tel {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__city {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background: #f8f8f8;
      }

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        border: 1px solid #f2994a;
        font-family: "Play";
        font-weight: 700;
        font-size: 20px;
        line-height: 19px;
        color: #fff;
        background-color: #fff;
        background-color: $hover-color;
        margin-top: 10px;
        &:hover {
          cursor: pointer;
          background-color: #e0893c;
        }
      }
      &__status {
        visibility: hidden;
        margin-top: 10px;
        font-family: "Play";
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        text-align: center;
        display: none;
        &.is-active {
          visibility: visible;
          display: block;
        }
      }
    }
  }
  &__product-add {
    display: none;
    &.is-active {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }
    &__wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 350px;
      width: 100%;
      height: 60px;
      background: #ffffff;
      p {
        font-family: "Play";
        font-weight: 700;
        font-size: 18px;
        line-height: 31px;
        text-transform: uppercase;
        text-align: center;
        padding: 0 15px;
        color: $hover-color;
      }
    }
  }
}
