/*
CLEARFIX STYLE
 */

.clearfix,
.container,
.row {
  &::after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}