.contacts {
    .container-internal {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1365px) {
            flex-direction: column;
        }
        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }
    &__info {
        max-width: calc(100% - 33% - 20px);
        width: 100%;
        @media screen and (max-width: 1365px) {
            max-width: calc(100% - 15%);
            margin-bottom: 20px;
        }
        @media screen and (max-width: 1024px) {
            max-width: 100%;
        }
        .container-internal {
            padding: 0 20px;
        }
        &__contact-info {
            margin-bottom: 20px;
            &__top {
                display: flex;
                align-items: center;
                padding-left: 35px;
                width: 100%;
                height: 90px;
                background: #fbfbfb;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 576px) {
                    font-size: 18px;
                    height: 70px;
                    padding-left: 10px;
                }
            }
            &__bottom {
                padding: 50px;
                width: 100%;
                background-color: #fff;
                display: flex;
                flex-wrap: wrap;
                @media screen and (max-width: 576px) {
                    flex-direction: column;
                    padding: 10px;
                }
                &__phone {
                    width: 50%;
                    margin-bottom: 20px;
                    @media screen and (max-width: 576px) {
                        width: 100%;
                    }
                    p {
                        padding: 0;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        color: #828282;
                    }
                    a {
                        font-family: "Play";
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 28px;
                        text-decoration: none;
                        &:hover {
                            cursor: pointer;
                            color: $hover-color;
                        }
                        @media screen and (max-width: 576px) {
                            font-size: 18px;
                        }
                    }
                }
                &__mail {
                    width: 50%;
                    margin-bottom: 20px;
                    @media screen and (max-width: 576px) {
                        width: 100%;
                    }
                    p {
                        padding: 0;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        color: #828282;
                    }
                    a {
                        font-family: "Play";
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 28px;
                        text-decoration: none;
                        &:hover {
                            cursor: pointer;
                            color: $hover-color;
                        }
                        @media screen and (max-width: 576px) {
                            font-size: 18px;
                        }
                    }
                }
                &__work {
                    width: 50%;
                    margin-bottom: 20px;
                    @media screen and (max-width: 576px) {
                        width: 100%;
                    }
                    p {
                        padding: 0;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        color: #828282;
                    }
                    span {
                        font-family: "Play";
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 28px;
                        @media screen and (max-width: 576px) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        &__our-stores {
            &__top {
                display: flex;
                align-items: center;
                width: 100%;
                height: 90px;
                padding-left: 35px;
                background: #fbfbfb;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 576px) {
                    font-size: 18px;
                    height: 70px;
                    padding-left: 10px;
                }
            }
            &__bottom {
                padding: 50px;
                width: 100%;
                background-color: #fff;
                @media screen and (max-width: 576px) {
                    padding: 10px;
                }
                .store {
                    display: flex;
                    justify-content: space-between;
                    max-width: 1090px;
                    width: 100%;
                    margin-bottom: 20px;
                    border: 5px solid #f4f4f4;
                    @media screen and (max-width: 1024px) {
                        flex-direction: column;
                    }
                    &__info {
                        padding: 40px;
                        @media screen and (max-width: 576px) {
                            padding: 20px;
                        }
                        &__address {
                            margin-bottom: 20px;
                            p {
                                padding: 0;
                                font-size: 14px;
                                line-height: 19px;
                                color: #828282;
                            }
                            span {
                                font-family: "Play";
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 21px;
                                @media screen and (max-width: 576px) {
                                    font-size: 16px;
                                }
                                &.plus-address {
                                    font-size: 16px;
                                    font-weight: normal;
                                }
                            }
                        }
                        &__phones {
                            margin-bottom: 20px;
                            p {
                                padding: 0;
                                font-size: 14px;
                                line-height: 19px;
                                color: #828282;
                            }
                            &__phone {
                                br {
                                    @media screen and (max-width: 1024px) {
                                        display: none;
                                    }
                                    @media screen and (max-width: 576px) {
                                        display: block;
                                    }
                                }
                                a {
                                    font-family: "Play";
                                    font-weight: 700;
                                    font-size: 18px;
                                    line-height: 21px;
                                    margin-right: 10px;
                                    text-decoration: none;
                                    &:hover {
                                        cursor: pointer;
                                        color: $hover-color;
                                    }
                                    @media screen and (max-width: 576px) {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                        &__working-hours {
                            margin-bottom: 20px;
                            @media screen and (max-width: 576px) {
                                margin-bottom: 0;
                            }
                            p {
                                padding: 0;
                                font-size: 14px;
                                line-height: 19px;
                                color: #828282;
                            }
                            span {
                                font-family: "Play";
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 21px;
                                @media screen and (max-width: 576px) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    &__map {
                        max-width: 517px !important;
                        width: 100% !important;
                        @media screen and (max-width: 1024px) {
                            max-width: 100% !important;
                        }
                        > div {
                            height: 100%;
                        }
                        iframe {
                            max-width: 517px !important;
                            width: 100% !important;
                            // max-height: 100%;
                            // height: 260px !important;
                            height: 100% !important;
                            @media screen and (max-width: 1024px) {
                                max-width: 100% !important;
                                // height: 260px !important;
                            }
                            @media screen and (max-width: 576px) {
                                height: 160px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    &__write-us {
        position: sticky;
        top: 10px;
        max-width: calc(100% - 67%);
        width: 100%;
        max-height: 700px;
        margin-top: 20px;
        padding: 15px 40px;
        background-color: #fff;
        @media screen and (max-width: 1365px) {
            max-width: 584px;
            margin: 0 auto;
        }
        @media screen and (max-width: 576px) {
            position: relative;
            top: 0;
            padding: 10px;
        }
        label {
            font-size: 16px;
            line-height: 22px;
            color: #4f4f4f;
            font-weight: 600;
            @media screen and (max-width: 576px) {
                font-size: 12px;
            }
        }
        &__title {
            height: 90px;
            background: #fbfbfb;
            display: flex;
            align-items: center;
            padding-left: 35px;
            margin-bottom: 15px;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: $black;
            @media screen and (max-width: 576px) {
                font-size: 18px;
            }
        }
        &__name {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            padding: 15px;
            background: #f8f8f8;
            input {
                background: #f8f8f8;
                border: none;
                font-size: 18px;
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }
        &__mail {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            padding: 15px;
            background: #f8f8f8;
            input {
                background: #f8f8f8;
                border: none;
                font-size: 18px;
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }
        &__phone {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            padding: 15px;
            background: #f8f8f8;
            input {
                background: #f8f8f8;
                border: none;
                font-size: 18px;
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }
        &__question {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            background: #f8f8f8;
            textarea {
                padding: 15px;
                background: #f8f8f8;
                border: none;
                resize: none;
                overflow: auto;
                font-size: 18px;
                height: 176px;
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }
        &__submit {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 55px;
            background-color: #fff;
            border: 2px solid $hover-color;
            color: #000;
            font-family: "Play";
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            &:hover {
                background-color: $hover-color;
                cursor: pointer;
                color: #fff;
            }
            @media screen and (max-width: 576px) {
                font-size: 16px;
            }
        }
        &__status {
            display: none;
            font-weight: bold;
            margin-top: 10px;
            font-size: 16px;
            line-height: 27px;
            color: $black;
            &.is-active {
                display: block;
            }
            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }
    }
}

.stores-tab {
    width: 100%;
    margin-top: 20px;
    &__title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 90px;
        padding-left: 35px;
        background: #fbfbfb;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        border-bottom: 1px solid #e8e8e8;
        @media screen and (max-width: 576px) {
            font-size: 18px;
            height: 70px;
            padding-left: 10px;
        }
    }
    &__content {
        background: #ffffff;
        padding: 60px;
        padding-bottom: 300px;
        @media screen and (max-width: 1024px) {
            padding: 30px;
        }
        @media screen and (max-width: 767px) {
            padding: 15px;
        }
        .nav-stores {
            display: flex;
            margin-bottom: 25px;
            @media screen and (max-width: 767px) {
                flex-wrap: wrap;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 767px) {
                flex-wrap: wrap;
                margin-bottom: 0px;
            }
            .store {
                position: relative;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                color: #828282;
                margin-right: 30px;
                padding-bottom: 2px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 15px;
                }
                &.is-active {
                    color: $hover-color;
                    &:before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        border-bottom: 2px solid $hover-color;
                    }
                }
                &:hover {
                    cursor: pointer;
                    color: $hover-color;
                    &:before {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        border-bottom: 2px solid $hover-color;
                    }
                }
            }
        }
        .info-stores {
            display: none;
            &.is-active {
                display: block;
            }
            .store {
                &--slider {
                    margin-bottom: 60px;
                    @media screen and (max-width: 767px) {
                        margin-bottom: 30px;
                    }
                    &__main-img {
                        position: relative;
                        width: 100%;
                        // max-height: 600px;
                        // height: auto;
                        margin-bottom: 20px;
                        padding-top: 37%;
                        @media screen and (max-width: 767px) {
                            margin-bottom: 10px;
                        }
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                    &__swiper {
                        display: flex;
                        align-items: center;
                        height: 80px;
                        @media screen and (max-width: 576px) {
                            height: 50px;
                        }
                        .swiper-button-prev {
                            position: relative;
                            background: #f2f2f2;
                            width: 80px;
                            height: 80px;
                            left: 0;
                            right: 0;
                            top: 0;
                            margin-right: 20px;
                            margin-top: 0;
                            @media screen and (max-width: 1365px) {
                                margin-right: 15px;
                            }
                            @media screen and (max-width: 767px) {
                                margin-right: 10px;
                            }
                            @media screen and (max-width: 576px) {
                                width: 20px;
                                height: 50px;
                            }
                            &:after {
                                font-size: 17px;
                                font-weight: 900;
                                color: #828282;
                            }
                            &.none {
                                display: none;
                            }
                        }
                        .swiper-button-next {
                            position: relative;
                            background: #f2f2f2;
                            width: 80px;
                            height: 80px;
                            left: 0;
                            right: 0;
                            top: 0;
                            margin-left: 20px;
                            margin-top: 0;
                            @media screen and (max-width: 1365px) {
                                margin-left: 15px;
                            }
                            @media screen and (max-width: 767px) {
                                margin-left: 10px;
                            }
                            @media screen and (max-width: 576px) {
                                width: 20px;
                                height: 50px;
                            }
                            &:after {
                                font-size: 17px;
                                font-weight: 900;
                                color: #828282;
                            }
                            &.none {
                                display: none;
                            }
                        }
                        .contacts-slider {
                            width: 100%;
                            .swiper-wrapper {
                                .swiper-slide {
                                    height: 80px;
                                    @media screen and (max-width: 576px) {
                                        height: 50px;
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        // height: auto;
                                        object-fit: cover;
                                        object-position: center;
                                    }
                                    &.is-active {
                                        border: 2px solid #f2994a;
                                    }
                                    &:hover {
                                        cursor: pointer;
                                        border: 2px solid #f2994a;
                                    }
                                }
                            }
                        }
                    }
                }
                &--products {
                    &--title {
                        margin-bottom: 30px;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 27px;
                    }
                    .products {
                        // display: grid !important;
                        // grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
                        // grid-gap: 0;
                        border-top: 1px solid #f2f2f2;
                        border-left: 1px solid #f2f2f2;
                    }
                    .product-item {
                        // width: 100%;
                        width: 16.6%;
                        @media screen and (max-width: 1440px) {
                            width: 20%;
                        }
                        @media screen and (max-width: 1365px) {
                            width: 25%;
                        }
                        @media screen and (max-width: 1024px) {
                            width: 33.33%;
                        }
                        @media screen and (max-width: 767px) {
                            width: 50%;
                        }
                        @media screen and (max-width: 576px) {
                            width: 100%;
                        }
                        &:hover {
                            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        }
                        &__name {
                            font-size: 1.1rem;
                        }
                    }
                    .see-more {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 60px;
                        margin-top: 10px;
                        border: 1px solid #f2f2f2;
                        font-family: "Play";
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 140%;
                        color: $normal-color;
                        &:hover {
                            cursor: pointer;
                            color: $hover-color;
                            span {
                                background: $normal-color;
                            }
                        }
                        @media screen and (max-width: 767px) {
                            height: 50px;
                        }
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 22px;
                            height: 22px;
                            margin-left: 10px;
                            background: $hover-color;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }
}
