.news-page {
  .news {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .news__preview {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25%;
      width: calc(25% - 15px);
      margin-right: 15px;
      margin-bottom: 15px;
      &:hover {
        box-shadow: 0 0px 10px rgba(0,0,0,.3);
        .news__preview__text p {
            color: $hover-color;
        }
      }
      @media screen and (max-width: 1365px) {
        width: calc(33.33% - 15px);
      }
      @media screen and (max-width: 990px) {
        width: calc(50% - 15px);
      }
      @media screen and (max-width: 576px) {
        width: 100%;
        margin-right: 0;
      }
      .news__preview__img {
        img {
          width: 100%;
        }
      }

      .news__preview__date {
        background-color: rgba(225, 226, 236, 0.65);
        padding-left: 20px;
        font-size: 16px;
        line-height: 22px;
        @media screen and (max-width: 576px) {
          font-size: 14px;
        }
      }

      .news__preview__text {
        background-color: #fff;
        padding: 15px 20px;
        text-decoration: none;
        p {
          padding: 0;
          font-family: "Play";
          font-weight: 700;
          font-size: 20px;
          @media screen and (max-width: 576px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
