.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 340px;
    width: 100%;
    height: 100vh;
    z-index: 300;
    background-color: #fff;
    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80px;
        background-color: $normal-color;
    }
    &__slogan {
        padding: 5px 0;
        text-align: center;
        font-family: "Lobster";
        font-size: 19px;
        color: $normal-color;
    }
    &__search {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f8f8f8;
        form {
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                background-color: #f8f8f8;
                border: none;
                img {
                    margin-right: 15px;
                }
            }
            #search {
                width: 242px;
                height: 27px;
                background-color: #f8f8f8;
                border: none;
                border-bottom: 1px solid #bdbdbd;
                font-family: "Play";
                font-size: 16px;
                &::placeholder {
                    color: #bdbdbd;
                }
            }
        }
    }
    &__nav {
        overflow-y: auto;
        height: calc(100vh - 180px);
        &__wrapper {
            &__category {
                display: flex;
                align-items: center;
                height: 70px;
                &.is-active {
                    .sub-menu--wrap {
                        position: absolute;
                        top: 0;
                        left: 340px;
                        // z-index: 50;
                        display: flex;
                        overflow-x: hidden;
                        position: absolute;
                        height: 100vh;
                        width: 360px;
                        transition: width 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
                        // transition: width 0.5s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
                        // transition-delay: 0.3s;
                    }
                }
                &:hover {
                    background-color: $backg-categori;
                    // .sub-menu--wrap {
                    //   position: absolute;
                    //   top: 0;
                    //   left: 340px;
                    //   z-index: 50;
                    //   display: flex;
                    //   overflow-x: hidden;
                    //   position: absolute;
                    //   height: 100vh;
                    //   width: 360px;
                    //   transition: width 0.5s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
                    //   transition-delay: 0.3s;
                    // }
                }
                & > a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    border-bottom: 1px solid #e0e0e0;
                    text-decoration: none;
                    margin: 0 20px;
                    .img-wrap {
                        width: 32px;
                        display: flex;
                        justify-content: center;
                        margin-left: 20px;
                        margin-right: 30px;
                        img {
                            width: auto;
                            height: 32px;
                        }
                    }
                    p {
                        font-family: "Play";
                        font-size: 16px;
                        color: $black;
                        font-weight: 400;
                    }
                }
                .sub-menu--wrap {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    left: 340px;
                    height: 100vh;
                    width: 0;
                    transition: width 0.3s linear 0s;
                    overflow: hidden;
                    background-color: $backg-categori;
                    padding-bottom: 20px;
                    &.is-active {
                        .sub-menu--wrap__title {
                            // display: flex;
                            width: 100%;
                        }
                        .sub-menu {
                            width: 360px;
                        }
                    }
                    &__title {
                        display: flex;
                        // display: none;
                        justify-content: space-between;
                        align-items: center;
                        width: 0;
                        max-height: 128px;
                        height: 100%;
                        padding-left: 30px;
                        p {
                            padding: 0;
                            font-family: "Play";
                            font-size: 22px;
                            color: $black;
                            font-weight: 400;
                            white-space: nowrap;
                        }
                        .close-side-bar {
                            margin-right: 30px;
                            transition: 0.5s;
                            &:hover {
                                cursor: pointer;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
                .sub-menu {
                    overflow-x: hidden;
                    display: flex;
                    flex-wrap: wrap;
                    width: 0;
                    // width: 360px;
                    height: 100vh;
                    z-index: 300;
                    padding: 20px 40px 0;
                    padding-right: 10px;
                    // &:after {
                    //   position: absolute;
                    //   content: " (" attr(data-name) ") ";
                    //   top: 30px;
                    //   font-family: "Play";
                    //   font-size: 22px;
                    //   color: $black;
                    //   font-weight: 400;
                    //   white-space: nowrap;
                    // }
                    .mCSB_container {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        margin-right: 0;
                    }
                    &__child {
                        width: 0;
                        width: 127px;
                        &:nth-child(odd) {
                            margin-right: 25px;
                        }
                        &:last-child {
                            margin-bottom: 100px;
                        }
                        // &:hover {
                        //     a {
                        //         .img-wrapper {
                        //             img {
                        //                 display: none;
                        //                 height: 100%;
                        //                 &.img__hov {
                        //                     display: block;
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }
                        a {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            text-decoration: none;
                            .img-wrapper {
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-start;
                                width: 87px;
                                height: 87px;
                                margin-bottom: 15px;
                                svg {
                                    width: auto;
                                    height: 100%;
                                    object-fit: contain;
                                    object-position: bottom;
                                    &.img__hov {
                                        display: none;
                                    }
                                }
                            }
                            p {
                                font-family: "Play";
                                font-size: 16px;
                                color: $black;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes slider-effect {
    from {
        right: -360px;
    }
    to {
        right: 0px;
    }
}
