.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media screen and (max-width: 576px) {
        width: 100%;
        height: 40px;
        justify-content: space-between;
    }
    @media screen and (max-width: 425px) {
        height: 35px;
    }
    @media screen and (max-width: 375px) {
        height: 30px;
    }

    &--section, .page-item {

        @media screen and (max-width: 576px) {
            width: calc(11.11% - 2px);
        }

        a, span {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2px;
            background-color: #ffffff;
            font-family: "Play";
            font-weight: 700;
            color: #4f4f4f;
            font-size: 18px;
            text-decoration: none;

            &:first-child {
                font-weight: 900;
            }

            @media screen and (max-width: 1024px) {
                width: 40px;
                height: 40px;
                font-size: 16px;
            }
            @media screen and (max-width: 576px) {
                // width: 35px;
                // height: 35px;
                width: 100%;
                height: 100%;
                font-size: 14px;
            }
            @media screen and (max-width: 378px) {
                // width: 27px;
                // height: 27px;
                font-size: 13px;
            }
        }

        &.active {
            span {
                color: $hover-color;

                span {
                    border-color: $hover-color;
                }
            }
        }

        &.is-active {
            a {
                color: $hover-color;
            }
        }

        a:hover {
            color: $hover-color;

            span {
                border-color: $hover-color;
            }
        }
    }

    &__left {
        span {
            display: block;
            width: 8px;
            height: 8px;
            border-top: 3px solid #0b0f66;
            border-right: 3px solid #0b0f66;
            transform: rotate(-135deg);
        }
    }

    &__points {
        a {
            &:hover {
                color: #4f4f4f;
            }
        }
    }

    &__right {
        span {
            display: block;
            width: 8px;
            height: 8px;
            border-top: 3px solid #0b0f66;
            border-right: 3px solid #0b0f66;
            transform: rotate(45deg);
        }
    }
}
