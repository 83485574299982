#catalog {
    main {
        width: 100%;
    }
}

.test-wrap {
    display: flex;
    position: relative;
}

.filter {
    position: sticky;
    top: 0;
    max-width: 250px;
    width: 100%;

    @media screen and (max-width: 767px) {
        position: absolute;
        top: 184px;
        left: 20px;
        max-width: calc(100% - 40px);
        border: none;
        z-index: 140;
    }
    // @media screen and (max-width: 425px) {
    //   top: 184px;
    // }
    // @media screen and (max-width: 383px) {
    //   top: 184px;
    // }
    &--wrap {
        // position: sticky;
        // top: 0;
        border-right: 1px solid #dbdbdb;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    .filter__title {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        height: 70px;
        font-family: "Play";
        font-weight: 700;
        font-size: 20px;
        border-bottom: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;

        span {
            display: none;
            @media screen and (max-width: 767px) {
                display: block;
                position: relative;
                max-width: 34px;
                width: 100%;
                height: 100%;
                &.is-active {
                    &:after {
                        transform: rotate(0deg);
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 43%;
                    left: 35%;
                    width: 0;
                    height: 0;
                    border-top: 7px solid #999;
                    border-right: 7px solid transparent;
                    border-left: 7px solid transparent;
                    transform: rotate(-90deg);
                    transition: 0.2s;
                }
            }
        }

        @media screen and (max-width: 767px) {
            height: 50px;
        }
    }

    .filter__parameters {
        .filter__parameters__title {
            padding: 0 20px;
            display: flex;
            align-items: center;
            height: 50px;
            background-color: #f5f5f5;
            font-family: "Play";
            font-weight: 700;
            font-size: 16px;
            border-bottom: 1px solid #dbdbdb;
        }

        .filter__parameters__from-to {
            display: flex;
            align-items: center;
            height: 90px;
            padding: 0 20px;
            background-color: #ffffff;

            span {
                font-size: 14px;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input {
                width: 95px;
                height: 30px;
                padding: 0 10px;
                margin-top: 8px;
                font-size: 14px;
                color: #828282;
                border: 1px solid #bdbdbd;
                -moz-appearance: textfield;
            }

            .from {
                margin-right: 20px;
            }
        }

        .filter__parameters__parameter {
            background-color: #ffffff;

            li {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 60px;
                margin: 0 20px;
                border-bottom: 1px solid #e0e0e0;

                .control input {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                }

                .control_indicator {
                    display: block;
                    position: relative;
                    margin-left: 5px;
                    margin-right: 10px;
                    height: 20px;
                    width: 20px;
                    background: #fff;
                    border: 1px solid #bdbdbd;
                    border-radius: 0px;
                }

                .control input:checked ~ .control_indicator {
                    background: #e6e6e6;
                }

                .control input:disabled ~ .control_indicator {
                    background: #e6e6e6;
                    opacity: 0.6;
                    pointer-events: none;
                }

                .control_indicator:after {
                    box-sizing: unset;
                    content: "";
                    position: absolute;
                    display: none;
                }

                .control input:checked ~ .control_indicator:after {
                    display: block;
                }

                .control-checkbox .control_indicator:after {
                    left: 7px;
                    top: 3px;
                    width: 3px;
                    height: 8px;
                    border: solid #000000;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .filter__parameters__apply {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 52px;
        margin-bottom: 10px;
        background-color: $hover-color;
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        border: none;

        &:hover {
            background-color: #e0893c;
            cursor: pointer;
        }
    }

    .filter__parameters__clear {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 52px;
        margin-bottom: 10px;
        background-color: #fff;
        font-size: 16px;
        font-weight: 700;
        color: $hover-color;
        border: 1px solid $hover-color;

        &:hover {
            color: #fff;
            background-color: $hover-color;
            cursor: pointer;
        }
    }
}

.category {
    position: relative;
    //  &:after{
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   top: 20px;
    //   right: 0;
    //   width: 70px;
    //   border-top: 3px solid #0b0f66;
    //   @media screen and (max-width: 767px) {
    //     width: 45px;
    //   }
    // }
    h2 {
        margin: 50px 0;
        // &:after{
        //   display: none!important;
        // }
        @media screen and (max-width: 767px) {
            margin: 30px 0;
            overflow-y: hidden;
        }
        // @media screen and (max-width: 480px) {
        //  display: block;
        //  max-width: 192px;
        //  text-overflow: ellipsis;
        //  white-space: nowrap;
        //  width: 192px;
        //  overflow: hidden;

        // }
        @media screen and (max-width: 425px) {
            padding-right: 98px;
        }
    }

    .product-item {
        @media screen and (max-width: 1365px) {
            width: 33.33%;
        }
        @media screen and (max-width: 990px) {
            width: 50%;
        }
        @media screen and (max-width: 576px) {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .filter-face {
        display: none;
        @media screen and (max-width: 767px) {
            display: block;
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
        }
    }
}

.sorting {
    width: 100%;
    height: 50px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: #ffffff;
    @media screen and (max-width: 1200px) {
        justify-content: space-between;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 15px;
    }
    @media screen and (max-width: 576px) {
        padding: 0 15px;
    }

    .sorting__title {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }

    .sorting__slect {
        display: flex;
        align-items: center;
        margin-left: 40px;
        @media screen and (max-width: 1200px) {
            display: none;
        }

        li {
            margin-right: 40px;
            font-size: 16px;
            color: #828282;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                // font-weight: 700;
                color: #4f4f4f;
                cursor: pointer;
            }

            &.is-active {
                font-weight: 700;
                color: #4f4f4f;
            }

            @media screen and (max-width: 1024px) {
                font-size: 14px;
            }
        }
    }

    .catalog {
        display: none;
        @media screen and (max-width: 1200px) {
            display: block;
            min-width: 140px;
            max-width: 140px;
            width: 100%;
            .vs__dropdown-toggle {
                border: none;
            }
            .vs__search {
                margin: 0;
                padding: 0;
                display: none;
            }
            .vs__selected-options {
                width: 90%;
            }
            .vs__selected {
                display: block;
                width: 100%;
                font-size: 14px;
                font-weight: 700;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0;
                padding: 0;
            }

        }
    }
    .vs--single.vs--open .vs__selected {
        position: relative !important;
    }
    .jq-selectbox {
        display: none;
        @media screen and (max-width: 1200px) {
            display: block;
        }
        @media screen and (max-width: 567px) {
            max-width: 140px;
            width: 100%;
        }

        &.dropdown {
            .jq-selectbox__trigger-arrow {
                transform: rotate(0deg) !important;
            }
        }

        .jq-selectbox__select {
            border-radius: 0;
            border: none;
            box-shadow: none;
            background: #fff;
            padding: 0 35px 0 10px;

            .jq-selectbox__select-text {
                font-size: 14px;
                font-weight: 700;
            }

            .jq-selectbox__trigger {
                border: none;

                .jq-selectbox__trigger-arrow {
                    border-top: 7px solid #999;
                    border-right: 7px solid transparent;
                    border-left: 7px solid transparent;
                    transform: rotate(-90deg);
                    transition: 0.2s;
                }
            }
        }

        .jq-selectbox__dropdown {
            width: auto;
            border-radius: 0;
            border: none;
            box-shadow: none;
            background: #fff;

            ul {
                li {
                    font-size: 14px;

                    &.selected {
                        background-color: $hover-color;
                    }
                }
            }
        }
    }
}
