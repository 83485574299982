.sidebar-small {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 80px;
    width: 100%;
    height: 100vh;
    z-index: 300;
    background: #ffffff;
    border-right: 10px solid $backg-categori;
    &__search {
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        form {
            display: flex;
            justify-content: center;
						align-items: center;
						
            span {
                background-color: #f2f2f2;
								border: none;
								&.is-active {
									img {
										display: none;
									}
									.close {
										display: block;
									}
								}
                img {
                    margin: 0 auto;
								}
								.close {
									display: none;
								}
						}
            .wrap-input {
                overflow: hidden;
                position: absolute;
                left: 80px;
                display: flex;
                // display: none;
                align-items: center;
                width: 0;
                // width: 242px;
                height: 80px;
                background-color: #f2f2f2;
                transition: 0.2s;
                button {
                    background-color: #f2f2f2;
										border: none;
										margin-right: 10px;
                    img {
                        margin: 0 auto;
                    }
                }
                &.is-active {
                    width: 252px;
                    padding: 0 15px;
                    .search {
                        width: 252px;
                    }
                }
                .search {
                    width: 0;
                    // width: 242px;
                    background-color: #f2f2f2;
                    border: none;
                    border-bottom: 1px solid #bdbdbd;
                    font-family: "Play";
                    font-size: 16px;
                    transition: 0.2s;
                    &::placeholder {
                        color: #bdbdbd;
                    }
                }
            }
        }
    }
    &__nav {
        overflow-y: auto;
        height: calc(100vh - 80px);
        &__wrapper {
            &__category {
                display: flex;
                align-items: center;
                height: 80px;
                &.is-active {
                    .sub-menu-small--wrap {
                        position: absolute;
                        top: 0;
                        left: 80px;
                        // z-index: 50;
                        display: flex;
                        overflow-x: hidden;
                        position: absolute;
                        height: 100vh;
                        width: 360px;
                        transition: width 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
                        // transition-delay: 0.3s;
                    }
                }
                &:hover {
                    background-color: $backg-categori;
                    // .sub-menu-small--wrap {
                    //   position: absolute;
                    //   top: 0;
                    //   left: 80px;
                    //   z-index: 50;
                    //   display: flex;
                    //   overflow-x: hidden;
                    //   position: absolute;
                    //   height: 100vh;
                    //   width: 360px;
                    //   transition: width 0.5s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
                    //   transition-delay: 0.3s;
                    // }
                }
                &>a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    .img-wrap {
                        width: 32px;
                        display: flex;
                        justify-content: center;
                        margin: 0 auto;
                        img {
                            width: auto;
                            height: 32px;
                        }
                    }
                }
                .sub-menu-small--wrap {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 0;
                    left: 80px;
                    height: 100vh;
                    width: 0;
                    transition: width 0.3s linear 0s;
                    overflow: hidden;
                    background-color: $backg-categori;
                    padding-bottom: 20px;
                    &.is-active {
                        .sub-menu-small--wrap__title {
                            // display: flex;
                            width: 100%;
                        }
                        .sub-menu-small {
                            width: 360px;
                        }
                    }
                    &__title {
                        // display: none;
                        display: flex;
                        width: 0;
                        justify-content: space-between;
                        align-items: center;
                        max-height: 128px;
                        height: 100%;
                        padding-left: 30px;
                        p {
                            padding: 0;
                            font-family: "Play";
                            font-size: 22px;
                            color: $black;
                            font-weight: 400;
                            white-space: nowrap;
                        }
                        .close-side-bar {
                            margin-right: 30px;
                            transition: 0.5s;
                            &:hover {
                                cursor: pointer;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
                .sub-menu-small {
                    overflow: hidden;
                    display: flex;
                    flex-wrap: wrap;
                    width: 0;
                    // width: 360px;
                    height: 100vh;
                    z-index: 300;
                    padding: 20px 40px 0;
                    padding-right: 10px;
                    // &:after {
                    //   position: absolute;
                    //   content: attr(data-name);
                    //   top: 30px;
                    //   white-space: nowrap;
                    //   font-family: "Play";
                    //   font-size: 22px;
                    //   color: $black;
                    //   font-weight: 400;
                    // }
                    .mCSB_container {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        margin-right: 0;
                    }
                    &__child {
                        width: 127px;
                        &:nth-child(odd) {
                            margin-right: 25px;
                        }
                        // &:hover {
                        //     a {
                        //         .img-wrapper {
                        //             img {
                        //                 height: 100%;
                        //                 display: none;
                        //                 &.img__hov {
                        //                     display: block;
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }
                        a {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            text-decoration: none;
                            .img-wrapper {
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-start;
                                width: 87px;
                                height: 87px;
                                margin-bottom: 15px;
                                svg {
                                    height: 100%;
                                    width: auto;
                                    object-fit: contain;
                                    object-position: bottom;
                                    &.img__hov {
                                        display: none;
                                    }
                                }
                            }
                            p {
                                font-family: "Play";
                                font-size: 16px;
                                color: $black;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes slider-effect {
    from {
        right: -360px;
    }
    to {
        right: 0px;
    }
}

// @keyframes slider-effect-close {
//   from {
//     right: 0px;
//   }
//   to {
//     right: 0px;
//   }
// }
@keyframes slider-search {
    from {
        right: -242px;
    }
    to {
        right: 0px;
    }
}
