.order-number-page {
    margin-top: 20px;
    width: 100%;
    // min-height: calc(100vh - 289px);
    height: 100%;
    padding-bottom: 150px;
    background: url(./../img/bg-order-number-left.png) left, url(./../img/bg-order-number-right.png) right, #fff;
    background-repeat: no-repeat;
    @media screen and (max-width: 676px) {
        background: url(./../img/bg-discount-pop-up.png), #fff;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding-bottom: 240px;
    }
    @media screen and (max-width: 425px) {
        padding-bottom: 60px;
    }
    .order-number {
        padding-left: 170px;
        @media screen and (max-width: 767px) {
            padding: 0 10px;
        }
        &__title {
            display: flex;
            align-items: center;
            padding-top: 10%;
            font-family: "Play";
            font-size: 48px;
            line-height: 140%;
            margin-bottom: 25px;
            color: #333333;
            @media screen and (max-width: 767px) {
                font-size: 36px;
                margin-bottom: 15px;
            }
            img {
                margin-right: 20px;
            }
        }
        &__info {
            font-family: "Play";
            font-size: 24px;
            line-height: 140%;
            color: #333333;
            @media screen and (max-width: 767px) {
                font-size: 18px;
                padding-bottom: 20px;
            }
        }
        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 320px;
            width: 100%;
            height: 55px;
            background-color: $hover-color;
            margin-top: 30px;
            font-family: "Play";
            font-size: 18px;
            font-weight: 600;
            line-height: 21px;
            text-transform: uppercase;
            color: #fff;
            text-decoration: none;
            &:hover {
                background-color: #c67934;
            }
            @media screen and (max-width: 767px) {
                margin-top: 0;
                font-size: 14px;
                line-height: 16px;
                height: 45px;
            }
        }
    }
}
