.search-page {
  margin-bottom: 200px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 30px;
  }
  .search-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    input {
      max-width: 755px;
      width: 100%;
      height: 60px;
      padding: 0 30px;
      border: 2px solid #e0e0e0;
      border-right: none;
      font-size: 18px;
      @media screen and (max-width: 767px) {
        height: 40px;
        padding: 0 15px;
        font-size: 16px;
        font-size: 14px;
      }
      &::placeholder {
        color: #bdbdbd;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 131px;
      width: 100%;
      height: 60px;
      background-color: $hover-color;
      color: #fff;
      border: none;
      font-size: 18px;
      @media screen and (max-width: 767px) {
        max-width: 80px;
        height: 40px;
        font-size: 14px;
      }
      &:hover {
        background-color: #e0893c;
      }
    }
  }

  .found {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 50px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 20px;
    }
    br {
      display: none;
      @media screen and (max-width: 425px) {
        display: block;
      }
    }
  }
  .product-item {
    width: 20%;
    @media screen and (max-width: 1365px) {
      width: 25%;
    }
    @media screen and (max-width: 990px) {
      width: 33.33%;
    }
    @media screen and (max-width: 576px) {
      width: 50%;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
