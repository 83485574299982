.view-header {
    position: relative;
    width: 100%;
    order: 1;
}

header {
    position: relative;
    display: flex;
    z-index: 199;
    height: 80px;
    @media screen and (max-width: 767px) {
        height: 50px;
        background: $normal-color;
    }

    .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 250px;
        width: 250px;
        height: 80px;
        background: $normal-color;
        @media screen and (max-width: 767px) {
            min-width: 175px;
            width: 175px;
            height: 50px;
        }
    }

    #logo {
        @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
        }
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    background: #ffffff;
    @media screen and (max-width: 1365px) {
        position: relative;
        justify-content: space-between;
    }
    @media screen and (max-width: 767px) {
        justify-content: flex-end;
    }

    &__left {
        max-width: 700px;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 1365px) {
            position: relative;
            max-width: 80px;
            width: 80px;
            height: 100%;
            margin: 0 10px;
        }
        @media screen and (max-width: 767px) {
            display: none;
        }

        &__nav {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            margin-left: 10px;
            @media screen and (max-width: 1365px) {
                display: none;
                position: absolute;
                width: 200px;
                height: auto;
                top: 80px;
                left: -10px;
                margin-left: 0;
                background-color: #ffe8d3;
            }
            @media screen and (max-width: 767px) {
                display: none;
            }

            &.is-active {
                display: block;

                &:first-child {
                    border-top: 1px solid #f2f2f2;
                }

                li {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    height: 30px;
                    padding: 0 10px;
                    border-bottom: 1px solid #ffffff;
                }
            }

            li {
                // margin-right: 50px;
                display: flex;
                align-items: center;
                position: relative;
                font-family: "Play";
                font-size: 15px;
                color: $black;
                font-weight: 400;

                &:last-child {
                    margin-right: 15px;
                }

                &:hover {
                    & > a {
                        color: $hover-color;
                        cursor: pointer;
                    }

                    & > p {
                        color: $hover-color;
                        cursor: pointer;
                    }
                }

                &.is-active {
                    .sub-menu {
                        display: block;
                    }

                    .toggle-arrow {
                        transform: rotate(0deg);
                    }
                }

                a {
                    text-decoration: none;
                }

                p {
                    font-family: "Play";
                    font-size: 16px;
                    color: $black;
                    font-weight: 400;
                }

                .toggle-arrow {
                    margin-left: 10px;
                    border: 6px solid transparent;
                    border-right: none;
                    border-left: 8px solid #c4c4c4;
                    transition: all 0.3s;
                    transform: rotate(90deg);
                }

                .sub-menu {
                    position: absolute;
                    top: 100%;
                    left: -10px;
                    display: none;
                    // display: block;
                    width: 200px;
                    background-color: #fff;
                    @media screen and (max-width: 1365px) {
                        top: -2px;
                        left: 200px;
                        background-color: #ffe8d3;
                    }

                    li {
                        // height: 50px;
                        padding: 15px 20px;
                        border-bottom: 1px solid #f2f2f2;

                        &:first-child {
                            border-top: 1px solid #f2f2f2;
                        }

                        @media screen and (max-width: 1365px) {
                            border-color: #fff;
                        }
                    }
                }
            }
        }

        &__burger-menu {
            display: none;
            @media screen and (max-width: 1365px) {
                display: flex;
                align-items: center;
                justify-content: center;
                // min-width: 80px;
                height: 100%;
                font-family: "Play";
                font-size: 16px;
            }
            @media screen and (max-width: 767px) {
                display: none;
            }

            &.is-active {
                // background-color: #f2f2f2;
                svg {
                    display: none;
                    margin-right: 10px;

                    &.close {
                        display: block;
                    }
                }
            }

            svg {
                margin-right: 10px;

                &.close {
                    display: none;
                }
            }
        }
    }

    &__right {
        display: flex;

        &__discount {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 30px;
            text-decoration: none;
            @media screen and (max-width: 1500px) {
                margin-right: 10px;
            }
            @media screen and (max-width: 767px) {
                display: none;
            }

            &:hover {
                cursor: pointer;

                .header__right__discount__title {
                    color: $hover-color;
                    cursor: pointer;
                }
            }

            &__img {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 2px solid $discount-color;
                z-index: 10;
                background: #fcfdff;
                // @media screen and (max-width: 1024px) {
                //   position: relative;
                // }
            }

            &__title {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 200px;
                height: 38px;
                padding-left: 30px;
                border: 2px solid $discount-color;
                border-radius: 21px;
                font-family: "Play";
                font-size: 16px;
                color: $black;
                font-weight: 700;
                text-decoration: none;
                @media screen and (max-width: 1559px) {
                    width: 165px;
                    font-size: 13px;
                }
                @media screen and (max-width: 1024px) {
                    width: 175px;
                    font-size: 14px;
                }
            }
        }

        &__phone {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 20px;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            margin-right: 20px;
            @media screen and (max-width: 1366px) {
                padding: 5px 10px;
            }
            @media screen and (max-width: 767px) {
                border: none;
                margin-right: 0;
            }

            &__img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                margin-right: 13px;
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
                border-radius: 50%;
                @media screen and (max-width: 1024px) {
                    margin-right: 0;
                }
            }

            &__info {
                @media screen and (max-width: 1024px) {
                    display: none;
                    &.is-active {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #fff;
                        top: 80px;
                        width: 140px;
                        height: 40px;
                        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
                        @media screen and (max-width: 767px) {
                            top: 50px;
                        }
                    }
                }

                &__tel {
                    font-family: "Play";
                    font-size: 16px;
                    color: $black;
                    font-weight: 400;
                    text-decoration: none;
                    white-space: nowrap;

                    &:hover {
                        color: $hover-color;
                        cursor: pointer;
                    }
                }

                &__call-me {
                    font-family: "Play";
                    font-size: 14px;
                    color: $normal-color;
                    font-weight: 400;
                    text-decoration: underline;
                    white-space: nowrap;

                    &:hover {
                        color: $hover-color;
                        cursor: pointer;
                    }
                }
            }
        }

        &__basket {
            &--face {
                max-width: 160px;
                width: 160px;
                @media screen and (max-width: 1500px) {
                    width: 55px;
                }
                @media screen and (max-width: 1024px) {
                    width: 50px;
                }
                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            display: flex;
            align-items: center;
            justify-content: center;
            // width: 160px;
            text-decoration: none;
            position: fixed;
            top: 15px;
            right: 20px;
            // border: 1px solid #000;
            padding: 5px;
            background: #ffffff;
            border-radius: 25px;
            z-index: 100;
            box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);

            &:hover {
                .header__right__basket__total {
                    color: $hover-color;
                    cursor: pointer;
                }
            }

            @media screen and (max-width: 1500px) {
                right: 15px;
            }
            @media screen and (max-width: 767px) {
                display: none;
            }

            &__img {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
                border-radius: 50%;

                &:after {
                    content: attr(data-quantity);
                    position: absolute;
                    top: 0;
                    right: -10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    background-color: $discount-color;
                    border-radius: 50%;
                    color: #fff;
                    font-size: 14px;
                }
            }

            &__total {
                margin-left: 13px;
                margin-right: 5px;
                font-family: "Play";
                font-size: 18px;
                color: $black;
                font-weight: 400;
                white-space: nowrap;
                @media screen and (max-width: 1500px) {
                    display: none;
                }
            }
        }
    }

    &__search {
        position: relative;
        min-width: 50px;
        height: 50px;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;

        &.is-active {
            form {
                display: flex;
            }

            .header__search__activ img {
                display: none;
            }

            svg.close {
                display: block;
            }
        }

        @media screen and (max-width: 767px) {
            display: flex;
        }

        form {
            justify-content: center;
            overflow: hidden;
            position: absolute;
            left: -200px;
            // display: flex;
            display: none;
            align-items: center;
            width: 200px;
            height: 50px;
            padding-left: 10px;
            background-color: #f2f2f2;
            transition: 0.2s;

            button {
                background-color: #f2f2f2;
                border: none;

                img {
                    margin: 0 auto;
                }
            }

            .search {
                max-width: 150px;
                width: 100%;
                background-color: #f2f2f2;
                border: none;
                border-bottom: 1px solid #bdbdbd;
                font-family: "Play";
                font-size: 16px;
                transition: 0.2s;

                &::placeholder {
                    color: #bdbdbd;
                }
            }
        }

        &__activ {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            svg.close {
                display: none;
            }
        }
    }
}

.header-mob {
    position: relative;
    display: none;
    position: sticky;
    top: 0;
    width: 100%;
    height: 50px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    z-index: 150;
    @media screen and (max-width: 767px) {
        display: flex;
    }

    .header-mob__caption {
        display: flex;
        width: 80%;

        .header-mob__caption__menu {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 100%;
            background-color: #fff;
            border-right: 1px solid #f2f2f2;

            &.is-active {
                background-color: #f2f2f2;

                svg {
                    display: none;

                    &.close {
                        display: block;
                    }
                }
            }

            svg {
                &.close {
                    display: none;
                }
            }

            span {
                margin-left: 10px;
                font-family: "Play";
                font-weight: 400;
                font-size: 16px;
            }
        }

        .header-mob__caption__category {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60%;
            height: 100%;
            background-color: #fff;
            border-right: 1px solid #f2f2f2;
            text-decoration: none;

            &.is-active {
                background-color: #f2f2f2;

                svg {
                    display: none;

                    &.close {
                        display: block;
                    }
                }
            }

            svg {
                &.close {
                    display: none;
                }
            }

            span {
                margin-left: 10px;
                font-family: "Play";
                font-weight: 400;
                font-size: 16px;
            }
        }
    }

    .header-mob__basket {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        background-color: #fff;

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 40px;
            height: 40px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
            border-radius: 50%;

            &:after {
                content: attr(data-quantity);
                position: absolute;
                top: 0;
                right: -10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                background-color: $discount-color;
                border-radius: 50%;
                color: #fff;
                font-size: 14px;
            }
        }
    }

    .header-mob__content {
        display: none;

        &.is-active {
            display: block;
        }

        &__discount {
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 30px;
            text-decoration: none;

            &:hover {
                cursor: pointer;

                .header__right__discount__title {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &__img {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 2px solid $discount-color;
                z-index: 10;
                background: #fcfdff;
            }

            &__title {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 200px;
                height: 38px;
                padding-left: 30px;
                border: 2px solid $discount-color;
                border-radius: 21px;
                font-family: "Play";
                font-size: 16px;
                color: $black;
                font-weight: 700;
                text-decoration: none;
            }
        }
    }

    .menu {
        width: 100%;
        position: fixed;
        top: 100px;
        left: 0;
        height: calc(100% - 100px);
        background-color: #fff;

        ul {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            // height: calc(100vh - 100px);
            li {
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: flex-start;
                // height: 60px;
                padding: 0 15px;

                &:first-child {
                    a {
                        border: none;
                    }
                }

                &:last-child {
                    margin-bottom: 20px;
                }

                a,
                p {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 20px 0;
                    font-family: "Play";
                    font-weight: 400;
                    font-size: 16px;
                    text-decoration: none;
                    border-top: 1px solid #e0e0e0;
                }

                p {
                    .toggle-arrow {
                        margin-left: 10px;
                        border: 6px solid transparent;
                        border-right: none;
                        border-left: 8px solid #c4c4c4;
                        transition: all 0.3s;
                        transform: rotate(90deg);
                    }
                }

                &.is-active {
                    p {
                        .toggle-arrow {
                            transform: rotate(0deg);
                        }
                    }
                }

                .sub-menu {
                    display: none;
                    width: 100%;
                    height: auto;
                    overflow: inherit;
                }
            }
        }
    }

    .category {
        display: none;
        width: 100%;
        position: fixed;
        top: 100px;
        left: 0;
        height: calc(100% - 100px);
        background-color: #fff;

        ul {
            width: 100%;
            height: 100%;
            // height: calc(100vh - 100px);
            overflow-y: scroll;

            li {
                padding: 0 15px;

                a {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    border-bottom: 1px solid #e0e0e0;
                    text-decoration: none;

                    .img-wrap {
                        width: 32px;
                        display: flex;
                        justify-content: center;
                        margin-left: 20px;
                        margin-right: 30px;

                        img {
                            width: auto;
                            height: 32px;
                        }
                    }

                    p {
                        font-family: "Play";
                        font-size: 16px;
                        color: $black;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
