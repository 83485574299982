/*
VIEW-WRAPPER
 */

// ON LOAD PAGE
.is-load .view-wrapper {
  visibility: hidden;
}

.view-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 320px;
  min-height: 100%;
  padding: 0;
  overflow: hidden;
}

.container {
  position: relative;
  min-width: 320px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.container--wrap {
  // position: relative;
  z-index: 1;
  margin-left: 80px;
}
.container-mian {
  position: relative;
  max-width: 1580px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}
.container-internal {
  position: relative;
  max-width: 1830px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.container-one-news {
  position: relative;
  max-width: 1210px;
  width: 100%;
  margin: 0 auto;
}