.card-product {
    .container-internal {
        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }
    &__item {
        position: relative;
        display: flex;
        margin-top: 20px;
        background-color: #fff;
        @media screen and (max-width: 1365px) {
            flex-direction: column;
            align-items: center;
        }
        &__slider {
            display: none;
            width: calc(100% - 60%);
            margin: 50px 50px 35px;
            &.is-active {
                display: block;
            }
            @media screen and (max-width: 1365px) {
                width: calc(100% - 35%);
                margin: 0;
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 0 15px;
            }
            &.tabs__content {
                display: none;
            }
            &.tabs__content.active {
                display: block;
            }
            &__full {
                &__img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 800px) {
                        margin-top: 40px;
                    }
                    img {
                        width: 100%;
                        height: 400px;
                        object-fit: contain;
                        object-position: center;
                        @media screen and (max-width: 800px) {
                            height: 200px;
                        }
                        @media screen and (max-width: 767px) {
                            height: 160px;
                        }
                    }
                }
            }
            &--preview-wrap {
                position: relative;
                display: flex;
                margin-top: 20px;
                .swiper-button-next {
                    position: relative;
                    display: block;
                    width: 30px;
                    height: 83px;
                    margin: 0;
                    margin-left: 25px;
                    @media screen and (max-width: 767px) {
                        width: 20px;
                        height: 60px;
                    }
                    &:after {
                        font-weight: 900;
                        font-size: 20px;
                        color: $normal-color;
                        position: absolute;
                        top: 30px;
                        @media screen and (max-width: 767px) {
                            top: 15px;
                        }
                    }
                }
                .swiper-button-prev {
                    position: relative;
                    display: block;
                    width: 30px;
                    height: 83px;
                    margin: 0;
                    margin-right: 20px;
                    @media screen and (max-width: 767px) {
                        width: 20px;
                        height: 60px;
                    }
                    &:after {
                        font-weight: 900;
                        font-size: 20px;
                        color: $normal-color;
                        position: absolute;
                        top: 30px;
                        @media screen and (max-width: 767px) {
                            top: 15px;
                        }
                    }
                }
            }
            &__preview {
                // padding: 0 40px;
                // width: calc(100% - 100px);
                width: 100%;
                &__img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid transparent;
                    &.is-active {
                        border: 2px solid $hover-color;
                    }
                    &:hover {
                        cursor: pointer;
                        border: 2px solid $hover-color;
                    }
                    img {
                        width: 100%;
                        height: 84px;
                        object-fit: contain;
                        object-position: center;
                        @media screen and (max-width: 767px) {
                            height: 60px;
                        }
                    }
                }
                .swiper-slide-thumb-active {
                    border: 2px solid $hover-color;
                }
            }
        }
        &__characteristic {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 40%);
            padding-top: 50px;
            @media screen and (max-width: 1365px) {
                width: 100%;
                padding: 15px 0;
            }
            &__title {
                &__category {
                    margin-bottom: 5px;
                    padding: 0 15px;
                    font-size: 14px;
                    line-height: 18px;
                    text-transform: uppercase;
                    color: #bdbdbd;
                }
                &__name {
                    padding: 0 15px;
                    font-family: Play;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 35px;
                    @media screen and (max-width: 767px) {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
            }
            .title {
                // margin-bottom: 10px;
                font-size: 16px;
                line-height: 140%;
                color: #828282;
            }
            &__color {
                margin-top: 35px;
                padding: 0 15px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e8e8e8;
                .colors {
                    display: flex;
                    &__color {
                        width: 50px;
                        height: 28px;
                        margin-right: 15px;
                        border-radius: 4px;
                        &:last-child {
                            margin-right: 0;
                        }
                        &.is-active {
                            border: 2px solid $hover-color;
                        }
                        &:hover {
                            border: 2px solid $hover-color;
                            cursor: pointer;
                        }
                    }
                }
            }
            .jq-selectbox {
                &__select {
                    border: none;
                    box-shadow: none;
                    background: none;
                    border-radius: 0;
                    padding-left: 0;
                    &-text {
                        font-family: "Play";
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 140%;
                        @media screen and (max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }
                &__trigger {
                    border: none;
                }
                &__dropdown {
                    top: 100% !important;
                }
            }
            &__size {
                padding: 5px 15px;
                .size {
                    font-family: "Play";
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 140%;
                    @media screen and (max-width: 767px) {
                        font-size: 18px;
                    }
                }
            }
            &__equipment,
            &__text {
                padding: 5px 15px;
                border-bottom: 1px solid #e8e8e8;
            }
            &__materials {
                margin-top: 40px;
                margin-bottom: 20px;
                padding: 0 15px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 20px;
                }
                .materials {
                    font-family: "Play";
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 140%;
                    @media screen and (max-width: 767px) {
                        font-size: 18px;
                    }
                }
            }
            &__price {
                display: flex;
                @media screen and (max-width: 767px) {
                    flex-wrap: wrap;
                }
                .quantity {
                    //   max-width: 215px;
                    //   width: 100%;
                    width: calc(100% - 76%);
                    @media screen and (max-width: 1024px) {
                        width: 25%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 50%;
                    }
                    &__title {
                        display: flex;
                        align-items: center;
                        height: 35px;
                        background: #eeeeee;
                        margin: 0;
                        padding-left: 40px;
                        @media screen and (max-width: 1440px) {
                            padding-left: 20px;
                        }
                        @media screen and (max-width: 1024px) {
                            padding-left: 10px;
                        }
                    }
                    &__input-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 115px;
                        background: #f8f8f8;
                        @media screen and (max-width: 767px) {
                            height: 77px;
                        }
                        &__input {
                            display: flex;
                            .quantity__minus {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 30px;
                                height: 50px;
                                border-left: 1px solid #e0e0e0;
                                border-top: 1px solid #e0e0e0;
                                border-bottom: 1px solid #e0e0e0;
                                &:hover {
                                    background-color: #ffe8d3;
                                    cursor: pointer;
                                }
                                @media screen and (max-width: 1024px) {
                                    height: 40px;
                                }
                                @media screen and (max-width: 767px) {
                                    width: 25px;
                                }
                            }
                            .quantity__number {
                                width: 50px;
                                height: 50px;
                                border: 1px solid #e0e0e0;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 140%;
                                text-align: center;
                                color: $black;
                                @media screen and (max-width: 1024px) {
                                    width: 45px;
                                    height: 40px;
                                }
                                @media screen and (max-width: 767px) {
                                    width: 40px;
                                }
                            }
                            .quantity__plus {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 30px;
                                height: 50px;
                                border-right: 1px solid #e0e0e0;
                                border-top: 1px solid #e0e0e0;
                                border-bottom: 1px solid #e0e0e0;
                                &:hover {
                                    background-color: #ffe8d3;
                                    cursor: pointer;
                                }
                                @media screen and (max-width: 1024px) {
                                    height: 40px;
                                }
                                @media screen and (max-width: 767px) {
                                    width: 25px;
                                }
                            }
                        }
                    }
                }
                .price {
                    //   max-width: 300px;
                    //   width: 100%;
                    width: calc(100% - 69%);
                    @media screen and (max-width: 1024px) {
                        width: 25%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 50%;
                    }
                    &__title {
                        position: relative;
                        display: flex;
                        align-items: center;
                        height: 35px;
                        background: #fff2c5;
                        margin: 0;
                        padding-left: 40px;
                        font-size: 16px;
                        line-height: 140%;
                        color: #be7a3e;
                        @media screen and (max-width: 1440px) {
                            padding-left: 20px;
                        }
                        @media screen and (max-width: 1024px) {
                            padding-left: 10px;
                        }
                        .price-discount {
                            position: absolute;
                            right: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 47px;
                            height: 29px;
                            background: #eb5757;
                            border-radius: 25px;
                            font-family: "Play";
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            color: #fff;
                            @media screen and (max-width: 767px) {
                                right: 10px;
                            }
                        }
                    }
                    &__cost {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 115px;
                        padding-left: 40px;
                        background: rgba(255, 239, 191, 0.5);
                        @media screen and (max-width: 1440px) {
                            padding-left: 20px;
                            font-size: 24px;
                        }
                        @media screen and (max-width: 1024px) {
                            padding-left: 10px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 22px;
                            height: 77px;
                        }
                        &.discount {
                            p {
                                color: $discount-color;
                            }
                            span {
                                display: block;
                            }
                        }
                        span {
                            display: none;
                            font-size: 16px;
                            line-height: 140%;
                            color: $black;
                            text-decoration: line-through;
                        }
                        p {
                            font-family: "Play";
                            font-weight: 700;
                            font-size: 30px;
                            line-height: 140%;
                            color: $black;
                            padding: 0;
                            @media screen and (max-width: 1440px) {
                                font-size: 24px;
                            }
                            @media screen and (max-width: 767px) {
                                font-size: 22px;
                            }
                        }
                    }
                }
                .buy {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    //   max-width: 470px;
                    //   width: 100%;
                    width: calc(100% - 55%);
                    height: 150px;
                    background: #f8f8f8;
                    @media screen and (max-width: 1024px) {
                        width: 50%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                    &__one-click {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 285px;
                        width: 100%;
                        height: 40px;
                        background: #ffffff;
                        border: 1px solid $hover-color;
                        text-decoration: none;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 140%;
                        color: $hover-color;
                        @media screen and (max-width: 1440px) {
                            max-width: 260px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                        }
                        &:hover {
                            cursor: pointer;
                            background: $hover-color;
                            color: #fff;
                        }
                    }
                    &__add-basket {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 285px;
                        width: 100%;
                        height: 60px;
                        margin-top: 5px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 140%;
                        color: #fff;
                        background: $hover-color;
                        &:hover {
                            cursor: pointer;
                            background: #e0893c;
                        }
                        @media screen and (max-width: 1440px) {
                            max-width: 260px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                        }
                        svg {
                            margin-right: 15px;
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
        &__vendor-code {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            width: 156px;
            height: 30px;
            background: rgba(168, 168, 168, 0.11);
            font-size: 14px;
            line-height: 16px;
            color: $black;
            z-index: 20;
            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }
    &__info {
        margin-top: 20px;
        background: #fff;
        &__nav {
            .mCSB_container {
                display: flex;
                width: 100%;
                height: 60px;
                border-bottom: 1px solid #f2f2f2;
                margin-bottom: 10px !important;
            }
            .mCSB_scrollTools {
                z-index: 1 !important;
            }
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 200px;
                width: 100%;
                height: 100%;
                font-family: "Play";
                font-size: 20px;
                line-height: 23px;
                text-align: center;
                color: #4f4f4f;
                padding: 5px;
                &.is-active {
                    background: #f2f2f2;
                }
                &:hover {
                    cursor: pointer;
                    background: #f2f2f2;
                }
                @media screen and (max-width: 767px) {
                    //   max-width: 50%;
                    min-width: 120px;
                    max-width: 120px;
                    font-size: 18px;
                }
            }
            &__reviews {
                position: relative;
                &::after {
                    content: attr(data-quantity);
                    position: absolute;
                    top: 38%;
                    right: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 27px;
                    height: 18px;
                    background: $discount-color;
                    border-radius: 19px;
                    //   font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #fff;
                    @media screen and (max-width: 767px) {
                        top: 10%;
                    }
                }
            }
        }
        &__content {
            display: none;
            padding: 45px;
            @media screen and (max-width: 1024px) {
                padding: 20px;
            }
            &.is-active {
                display: block;
            }
            &__description {
                .text-info {
                    margin: 0;
                    &__text-wrap {
                        min-height: auto;
                    }
                }
            }
            &__reviews {
                .leave-comment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 70px;
                    margin-bottom: 40px;
                    background: #f4f4f4;
                    @media screen and (max-width: 767px) {
                        max-width: 100%;
                        height: auto;
                        margin-bottom: 15px;
                    }
                    &__text {
                        margin-left: 35px;
                        font-family: "Play";
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 28px;
                        color: #4f4f4f;
                        @media screen and (max-width: 1024px) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                    &__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 202px;
                        width: 100%;
                        height: 51px;
                        background: $hover-color;
                        margin-right: 15px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 140%;
                        color: #fff;
                        &:hover {
                            cursor: pointer;
                            background: #e0893c;
                        }
                        @media screen and (max-width: 767px) {
                            max-width: 100%;
                            // font-size: 16px;
                            margin: 0;
                        }
                    }
                }
                .review {
                    display: flex;
                    margin-bottom: 20px;
                    border: 4px solid #f4f4f4;
                    @media screen and (max-width: 767px) {
                        flex-direction: column;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .review__info {
                        width: calc(100% - 80%);
                        padding: 33px 29px 33px 40px;
                        @media screen and (max-width: 1440px) {
                            padding: 15px;
                        }
                        @media screen and (max-width: 1200px) {
                            width: calc(100% - 75%);
                        }
                        @media screen and (max-width: 1024px) {
                            width: calc(100% - 65%);
                        }
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            padding: 10px;
                        }
                        &__name {
                            font-family: "Play";
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            color: #4f4f4f;
                        }
                        &__data {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 5px;
                            font-size: 14px;
                            line-height: 19px;
                            color: #828282;
                            @media screen and (max-width: 767px) {
                                margin: 0;
                            }
                        }
                        &__rating {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 150px;
                            margin-top: 10px;
                            @media screen and (max-width: 767px) {
                                margin: 0;
                            }
                        }
                    }
                    &__text {
                        display: flex;
                        align-items: center;
                        width: calc(100% - 20%);
                        margin: 23px 0;
                        padding: 0 23px;
                        border-left: 1px solid #e0e0e0;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin: 0;
                            padding: 10px;
                            border-left: none;
                            border-top: 1px solid #e0e0e0;
                        }
                        p {
                            padding: 0;
                            font-size: 16px;
                            line-height: 150%;
                            color: $black;
                            @media screen and (max-width: 767px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
                .see-all {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 52px;
                    border: 2px solid #f4f4f4;
                    font-family: "Play";
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: $normal-color;
                    &:hover {
                        cursor: pointer;
                        color: $hover-color;
                    }
                    svg {
                        margin-left: 20px;
                    }
                }
            }
            &__system-elements {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .system-element {
                    display: flex;
                    flex-direction: column;    
                    justify-content: space-between;
                    width: calc(100% - 66.66% - 20px);
                    border: 4px solid #f2f2f2;
                    margin-right: 1%;
                    margin-bottom: 20px;
                    @media screen and (max-width: 1365px) {
                        width: calc(100% - 50% - 20px);
                    }
                    @media screen and (max-width: 960px) {
                        width: 100%;
                        margin-bottom: 20px;
                        margin-right: 0;
                    }
                    &--info {
                        display: flex;
                        width: 100%;
                        padding: 30px;
                        @media screen and (max-width: 576px) {
                            flex-direction: column;
                            align-items: center;
                            padding: 20px;
                        }
                        &--img {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: calc(100% - 70%);
                            @media screen and (max-width: 576px) {
                                width: 100%;
                                margin-bottom: 15px;
                            }
                            img {
                                width: 100%;
                                object-fit: contain;
                                object-position: center;
                                @media screen and (max-width: 576px) {
                                    width: 170px;
                                }
                            }
                        }
                        &--main {
                            width: calc(100% - 30%);
                            padding-left: 30px;
                            @media screen and (max-width: 576px) {
                                width: 100%;
                                padding: 0;
                            }
                            .title {
                                display: block;
                                height: 46px;
                                overflow: hidden;
                                margin-bottom: 30px;
                                font-family: "Play";
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 23px;
                                color: $black;
                                text-decoration: none;
                                &:hover {
                                    cursor: pointer;
                                    color: $hover-color;
                                }
                                @media screen and (max-width: 576px) {
                                    font-size: 18px;
                                }
                            }
                            .size {
                                display: flex;
                                align-items: center;
                                padding-top: 15px;
                                padding-bottom: 15px;
                                border-top: 1px solid #f2f2f2;
                                border-bottom: 1px solid #f2f2f2;
                                &--title {
                                    margin-right: 15px;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 140%;
                                    color: $black-light;
                                }
                                &--size {
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 140%;
                                    color: $black;
                                }
                            }
                            .quantity {
                                display: flex;
                                align-items: center;
                                margin-top: 15px;
                                &__title {
                                    margin-right: 15px;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 140%;
                                    color: $black-light;
                                }
                                &__input-wrap {
                                    width: 40%;
                                    &__input {
                                        display: flex;
                                        .quantity__minus {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            width: 30%;
                                            height: 45px;
                                            border-left: 1px solid #e0e0e0;
                                            border-top: 1px solid #e0e0e0;
                                            border-bottom: 1px solid #e0e0e0;
                                            &:hover {
                                                background-color: #ffe8d3;
                                                cursor: pointer;
                                            }
                                            @media screen and (max-width: 1024px) {
                                                height: 40px;
                                            }
                                            @media screen and (max-width: 767px) {
                                                width: 25px;
                                            }
                                        }
                                        .quantity__number {
                                            width: 40%;
                                            height: 45px;
                                            border: 1px solid #e0e0e0;
                                            font-weight: 700;
                                            font-size: 18px;
                                            line-height: 140%;
                                            text-align: center;
                                            color: $black;
                                            @media screen and (max-width: 1024px) {
                                                width: 45px;
                                                height: 40px;
                                            }
                                            @media screen and (max-width: 767px) {
                                                width: 40px;
                                            }
                                        }
                                        .quantity__plus {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            width: 30%;
                                            height: 45px;
                                            border-right: 1px solid #e0e0e0;
                                            border-top: 1px solid #e0e0e0;
                                            border-bottom: 1px solid #e0e0e0;
                                            &:hover {
                                                background-color: #ffe8d3;
                                                cursor: pointer;
                                            }
                                            @media screen and (max-width: 1024px) {
                                                height: 40px;
                                            }
                                            @media screen and (max-width: 767px) {
                                                width: 25px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &--cost {
                        display: flex;
                        width: 100%;
                        border-top: 2px solid #f2f2f2;
                        @media screen and (max-width: 576px) {
                            flex-direction: column;
                        }
                        &--price {
                            display: flex;
                            align-items: center;
                            width: calc(100% - 30%);
                            padding: 17px 0 17px 10px;
                            background: rgba(255, 239, 191, 0.5);
                            @media screen and (max-width: 576px) {
                                justify-content: center;
                                width: 100%;
                                padding: 0;
                                height: 54px;
                            }
                            .title {
                                margin-right: 5px;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 140%;
                                color: $black-light;
                            }
                            .sum {
                                font-weight: 600;
                                font-size: 19px;
                                line-height: 140%;
                            }
                        }
                        &--button {
                            display: flex;
                            width: calc(100% - 60%);
                            &.is-active {
                                width: calc(100% - 30%);
                                @media screen and (max-width: 576px) {
                                    width: 100%;
                                }
                                .add {
                                    display: none;
                                }
                                .cancel {
                                    display: flex;
                                }
                                .added {
                                    display: flex;
                                }
                            }
                            @media screen and (max-width: 576px) {
                                width: 100%;
                                height: 54px;
                            }
                            .add {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100%;
                                background-color: $hover-color;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 140%;
                                color: #fff;
                                &:hover {
                                    cursor: pointer;
                                    background-color: #e0893c;
                                }
                            }
                            .cancel {
                                display: none;
                                align-items: center;
                                justify-content: center;
                                width: 35%;
                                height: 100%;
                                background-color: #fff;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 140%;
                                color: $hover-color;
                                &:hover {
                                    cursor: pointer;
                                    background-color: $hover-color;
                                    color: #fff;
                                }
                            }
                            .added {
                                display: none;
                                align-items: center;
                                justify-content: center;
                                width: 65%;
                                height: 100%;
                                background-color: #e0e0e0;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 140%;
                                color: #fff;
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

main .rec-products {
    margin-bottom: 300px;
    @media screen and (max-width: 1024px) {
        margin-bottom: 0;
    }
    &__container {
        @media screen and (max-width: 767px) {
            padding-bottom: 60px;
        }
        &__wrapper {
            &__slide {
                .product-item {
                    width: 100%;
                }
            }
        }
        .swiper-pagination {
            display: none;
            color: $normal-color;
            @media screen and (max-width: 767px) {
                display: block;
                width: 80%;
            }
            width: 100%;
            left: auto;
        }
        .swiper-button-prev {
            display: none;
            width: 43px;
            height: 43px;
            background: $hover-color;
            border-radius: 50%;
            opacity: 1 !important;
            color: #fff;
            &:hover {
                background: #e0893c;
            }
            &.swiper-button-disabled {
                border: 2px solid $hover-color;
                background-color: #fff;
                color: $hover-color;
                &:after {
                    border-top: 3px solid $hover-color;
                    border-right: 3px solid $hover-color;
                }
            }
            &:after {
                content: "";
                position: absolute;
                top: 39%;
                left: 41%;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 3px solid #fff;
                border-right: 3px solid #fff;
                transform: rotate(-135deg);
            }
            @media screen and (max-width: 1200px) {
                display: flex;
            }
            @media screen and (max-width: 767px) {
                top: auto;
                bottom: 0;
                .swiper-button-prev.swiper-button-disabled {
                    background-color: #f2f2f2;
                }
            }
        }
        .swiper-button-next {
            display: none;
            width: 43px;
            height: 43px;
            background: $hover-color;
            border-radius: 50%;
            opacity: 1 !important;
            color: #fff;
            &:hover {
                background: #e0893c;
            }
            &.swiper-button-disabled {
                border: 2px solid $hover-color;
                background-color: #fff;
                color: $hover-color;
                &:after {
                    border-top: 3px solid $hover-color;
                    border-right: 3px solid $hover-color;
                }
            }
            &:after {
                content: "";
                position: absolute;
                top: 39%;
                left: 39%;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 3px solid #fff;
                border-right: 3px solid #fff;
                transform: rotate(45deg);
            }
            @media screen and (max-width: 1200px) {
                display: flex;
            }
            @media screen and (max-width: 767px) {
                top: auto;
                bottom: 0;
                .swiper-button-prev.swiper-button-disabled {
                    background-color: #f2f2f2;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                }
            }
        }
    }
}
