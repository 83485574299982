.super-offer-page {
  margin-bottom: 200px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 30px;
  }
  .product-item {
    width: 20%;
    @media screen and (max-width: 1365px) {
      width: 25%;
    }
    @media screen and (max-width: 990px) {
      width: 33.33%;
    }
    @media screen and (max-width: 576px) {
      width: 50%;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  &__sort-nav {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__filter {
    max-width: 350px;
    width: 100%;
    position: relative;
    z-index: 140;
    margin-bottom: 2px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin-bottom: 15px;
    }
    .filter--wrapper {
      display: none;
      position: absolute;
      top: 50px;
      width: 100%;
    }
    .filter__title {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f2f2f2;
      height: 50px;
      font-family: "Play";
      font-weight: 700;
      font-size: 20px;
      span {
        display: block;
        position: relative;
        max-width: 34px;
        width: 100%;
        height: 100%;
        &.is-active {
          &:after {
            transform: rotate(0deg);
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 43%;
          left: 35%;
          width: 0;
          height: 0;
          border-top: 7px solid #999;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          transform: rotate(-90deg);
          transition: 0.2s;
        }
      }
    }

    .filter__parameters {
      .filter__parameters__title {
        padding: 0 20px;
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #f5f5f5;
        font-family: "Play";
        font-weight: 700;
        font-size: 16px;
        border-bottom: 1px solid #dbdbdb;
      }

      .filter__parameters__from-to {
        display: flex;
        align-items: center;
        height: 90px;
        padding: 0 20px;
        background-color: #ffffff;
        span {
          font-size: 14px;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input {
          width: 95px;
          height: 30px;
          padding: 0 10px;
          margin-top: 8px;
          font-size: 14px;
          color: #828282;
          border: 1px solid #bdbdbd;
          -moz-appearance: textfield;
        }
        .from {
          margin-right: 20px;
        }
      }
      .filter__parameters__parameter {
        background-color: #ffffff;
        li {
          display: flex;
          align-items: center;
          height: 50px;
          margin: 0 20px;
          border-bottom: 1px solid #e0e0e0;
          .control input {
            position: absolute;
            z-index: -1;
            opacity: 0;
          }
          .control_indicator {
            position: relative;
            margin-left: 5px;
            margin-right: 10px;
            height: 20px;
            width: 20px;
            background: #fff;
            border: 1px solid #bdbdbd;
            border-radius: 0px;
          }
          .control input:checked ~ .control_indicator {
            background: #e6e6e6;
          }
          .control input:disabled ~ .control_indicator {
            background: #e6e6e6;
            opacity: 0.6;
            pointer-events: none;
          }
          .control_indicator:after {
            box-sizing: unset;
            content: "";
            position: absolute;
            display: none;
          }
          .control input:checked ~ .control_indicator:after {
            display: block;
          }
          .control-checkbox .control_indicator:after {
            left: 7px;
            top: 3px;
            width: 3px;
            height: 8px;
            border: solid #000000;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          span {
            font-size: 14px;
          }
        }
      }
    }

    .filter__parameters__apply {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      margin-bottom: 10px;
      background-color: $hover-color;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      border: none;
      &:hover {
        background-color: #e0893c;
        cursor: pointer;
      }
    }

    .filter__parameters__clear {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      margin-bottom: 10px;
      background-color: #fff;
      font-size: 16px;
      font-weight: 700;
      color: $hover-color;
      border: 1px solid $hover-color;
      &:hover {
        color: #fff;
        background-color: $hover-color;
        cursor: pointer;
      }
    }
  }
  .pagination {
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }
}
