.breadcrumbs {
  display: flex;
  align-items: center;
  width: 100%;
  height: 59px;
  padding: 10px 20px;
  background-color: #fff;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;

    li {
      font-size: 14px;
      color: #828282;
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        &:last-child {
          margin-right: 0;
          margin-top: 5px;
        }
      }
      
      a {
        position: relative;
        text-decoration: none;
        padding-right: 20px;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 7px;
          width: 7px;
          height: 7px;
          border-top: 2px solid #828282;
          border-left: 2px solid #828282;
          transform: rotate(135deg);
        }
        &:hover {
          color: $hover-color;
          cursor: pointer;
        }
      }
      span {
      }
    }
  }
}
