/*
INCLUDE ALL SASS FILES
 */

// CONFIGS
@import "config";
// include default and main variables

// PRELOADER
@import "_parts/preloader";

// SECTIONS
@import "_layouts/swiper";
// swiper
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
@import "./_libs/formstyler.css";
@import "_layouts/view-header";
// HEADER
@import "_layouts/view-section";
// CONTENT
@import "_layouts/view-footer";
// FOOTER
@import "_layouts/view-sidebar";
// SIDEBAR
@import "_layouts/view-breadcrumbs";
// catalog
@import "_layouts/view-sidebar-small";
// SIDEBAR
@import "_layouts/view-index";
// Index
@import "_layouts/view-products";
// Index
@import "_layouts/view-catalog";
// catalog
@import "_layouts/view-super-offer";
// super-offer
@import "_layouts/view-search";
// search
@import "_layouts/view-news";
// news
@import "_layouts/view-one-news";
//one-news
@import "_layouts/view-about-us";
//about-us
@import "_layouts/view-stocks";
//stocks
@import "_layouts/view-404";
//404
@import "_layouts/view-order-number";
//order-number
@import "_layouts/view-contacts";
//contacts
@import "_layouts/view-checkout";
//checkout
@import "_layouts/view-basket";
//basket
@import "_layouts/view-card-product";
//card-product
@import "_layouts/view-pop-ups";
//pop-ups
@import "_layouts/view-furniture-catalog";
//furniture-catalog
// PARTS
@import "_parts/breadcrumbs";
// breadcrumbs
@import "_parts/buttons";
// buttons
@import "_parts/pagination";
// pagination
//vue-select
@import "~vue-select/src/scss/vue-select.scss";

body {
    &.of-hidden {
        overflow: hidden;
    }
}

main {
    width: 100%;
    min-height: calc(100vh - 210px);
}

h2 {
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;
    width: 100%;
    padding: 0;
    font-family: "Play";
    font-size: 27px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 100px;
    margin: 50px 0;
    text-overflow: ellipsis;
    @media screen and (max-width: 767px) {
        margin: 30px 0;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 45%;
        right: 0;
        width: 70px;
        border-top: 3px solid #0b0f66;
        @media screen and (max-width: 767px) {
            width: 45px;
        }
    }

    @media screen and (max-width: 767px) {
        font-size: 24px;
        padding-right: 50px;

    }
}

.mCSB_inside > .mCSB_container {
    margin-right: 0 !important;
}

.mCSB_scrollTools {
    z-index: 200;
}

.sidebars {
    .sidebar {
        display: block;
        @media screen and (max-width: 1365px) {
            display: none;
        }
    }

    .sidebar-small {
        display: none;
        @media screen and (max-width: 1365px) {
            display: block;
        }
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.sidebar-small {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.container--wrap {
    @media screen and (max-width: 767px) {
        margin-left: 0;
    }
}

.home {
    header {
        .logo {
            display: none;
            @media screen and (max-width: 1365px) {
                display: flex;
                width: 175px;
            }
            @media screen and (max-width: 767px) {
                height: 50px;
            }
        }
    }

    .container--wrap {
        margin-left: 340px;
        @media screen and (max-width: 1365px) {
            margin-left: 80px;
        }
        @media screen and (max-width: 767px) {
            margin-left: 0;
        }
    }

    .container-mian {
        max-width: 1580px;
    }

}

input[type='number'] {
    -moz-appearance: textfield;
}

.hidden {
    display: none !important;
}

.suggestions-wrapper .suggestions-promo {
    display: none !important;
}
