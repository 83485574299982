.one-news-page {
  .article {
    &__title {
      margin-top: 40px;
      font-family: "Play";
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 21px;
      }
    }
    &__bate {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 87px;
      height: 22px;
      margin-top: 10px;
      background: rgba(225, 226, 236, 0.65);
      font-size: 14px;
      line-height: 19px;
    }
    &__text {
      margin-top: 20px;
      p {
        padding: 0;
        font-size: 16px;
        line-height: 170%;
        padding-bottom: 50px;
        @media screen and (max-width: 767px) {
          padding-bottom: 20px;
          line-height: 150%;
        }
      }

      ul {
        li {
          font-size: 16px;
          line-height: 150%;
          // list-style: none;
        }
      }

      ol {
        li {
          font-size: 16px;
          line-height: 150%;
        }
      }
    }
  }
}
