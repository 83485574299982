.error-page {
  width: 100%;
  height: calc(100vh - 160px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 15%;
  padding-left: 20px;
  @media screen and (max-width: 767px) {
    padding-top: 50px;
    padding-left: 0;
  }
  .message-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 548px;
    width: 100%;
    background-color: #ffffff;
    padding: 40px 20px;
    @media screen and (max-width: 767px) {
      margin: 0 auto;
      padding: 30px 20px;
    }
    @media screen and (max-width: 375px) {
      padding: 25px 10px;
    }
    &__title {
      font-family: "Play";
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      color: $normal-color;
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 25px;
      }
    }

    &__text {
      margin-top: 15px;
      font-size: 20px;
      line-height: 27px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 19px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 326px;
      width: 100%;
      height: 55px;
      margin: 0 auto;
      background-color: $hover-color;
      margin-top: 30px;
      font-family: "Play";
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        background-color: #c67934;
      }
      @media screen and (max-width: 767px) {
        margin-top: 15px;
        font-size: 14px;
        line-height: 16px;
        height: 45px;
      }
    }
  }
}
.error-page ~ footer {
  margin-top: 0;
}