.view-footer {
  position: relative;
  width: 100%;
  order: 3;
}
footer {
  background: #333333;
  margin-top: 30px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: 100%;
  }
  .footer__copywriting {
    font-size: 16px;
    color: #d0d2e9;
    line-height: 19px;
    br {
      display: none;
      @media screen and (max-width: 800px) {
        display: block;
      }
    }
    @media screen and (max-width: 800px) {
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
      padding: 30px 0;
      border-bottom: 1px solid #2D2D2D;
    }
  }

  .footer__social-net {
    display: flex;
    justify-content: space-between;
    max-width: 144px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      max-width: 120px;
    }
    @media screen and (max-width: 800px) {
      align-items: center;
      width: 100%;
      padding: 30px 0;
    }
  }

  .footer__autor {
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      justify-content: center;
      width: 100%;
      padding: 30px 0;
      border-top: 1px solid #2D2D2D;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      color: #828282;
      margin-right: 15px;
    }
  }
}
