.products {
    width: 100%;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    display: flex;
    flex-wrap: wrap;
}

.product-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    padding-top: 35px;
    background: #ffffff;
    border-right: 1px solid #f2f2f2;

    &:hover {
        box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.1);
        z-index: 50;

        @media screen and (max-width: 1024px) {
            box-shadow: none;
            z-index: 1;
        }

        .product-item__img {

            .img-hover {
                display: block;

                // opacity: 1;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }

            img {
                display: none;

                // opacity: 0;
                @media screen and (max-width: 1024px) {
                    display: block;
                }
            }
        }

        .product-item__main-info__buy {
            background-color: $hover-color;

            &:hover {
                cursor: pointer;
                background-color: #E0893C;
            }

            svg {
                path {
                    fill: #fff;
                }
            }
        }

        .product-item__second-info {
            display: block;
            box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

            @media screen and (max-width: 1024px) {
                display: none;
                box-shadow: none;
            }
        }
    }

    &__discount {
        position: absolute;
        top: 25px;
        left: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 47px;
        // height: 30px;
        width: 15%;
        height: 6%;
        background: $discount-color;
        border-radius: 25px;

        font-family: "Play";
        // font-size: 16px;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;

        z-index: 10;
    }

    &__img {
        margin: 0 20px;
        aspect-ratio: 1 / 1;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media screen and (max-width: 1440px) {
            margin-bottom: 5px;
        }

        .img-hover {
            display: none;
        }

        img {
            display: block;
            object-fit: cover;
        }
    }

    &__category {
        // margin-left: 30px;
        margin-left: 8%;
        margin-bottom: 15px;
        // font-size: 14px;
        font-size: 1rem;
        font-weight: 400;
        color: #bdbdbd;
        text-align: left;
        text-transform: uppercase;

        @media screen and (max-width: 1440px) {
            margin-bottom: 5px;
        }
    }

    &__name {
        // margin-left: 30px;
        height: 55px;
        overflow: hidden;
        margin-left: 8%;
        margin-bottom: 20px;
        font-family: "Play";
        font-size: 18px;
        font-size: 1.2rem;
        font-weight: 700;
        text-decoration: none;

        @media screen and (max-width: 1440px) {
            margin-bottom: 5px;
        }

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__main-info {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;

        &__price {
            // max-width: 260px;
            // width: 100%;
            width: 65%;
            // height: 100%;
            // padding-left: 30px;
            margin-left: 8%;

            &__title {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 400;
                color: #bdbdbd;

                span {
                    font-family: "Play";
                    font-size: 12px;
                    font-weight: 400;
                    color: $black;
                    text-decoration: line-through;
                }
            }

            &__sum {
                &.discount {
                    color: $discount-color;
                }

                // font-size: 22px;
                font-size: 1.4rem;
                font-weight: 700;
            }
        }

        &__buy {
            // max-width: 124px;
            // width: 100%;
            width: 35%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #f2f2f2;

        }
    }

    &__second-info {
        position: absolute;
        top: 98%;
        // bottom: 0;
        z-index: 15;
        display: none;
        // display: block;
        width: 100%;
        background: #ffffff;
        border-top: 1px solid #f2f2f2;

        .title {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #bdbdbd;
        }

        p {
            padding: 0;
            font-size: 18px;
            font-size: 1.2rem;
            font-weight: 700;
        }

        &__colors {
            width: 100%;
            //height: 90px;
            // padding-left: 30px;
            padding-left: 8%;
            padding-top: 20px;

            @media screen and (max-width: 1440px) {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 20px;

                @media screen and (max-width: 1440px) {
                    padding-bottom: 10px;
                }
            }

            .color-wrap {
                display: flex;

                .color {
                    // width: 45px;
                    width: 12%;
                    height: 25px;
                    border-radius: 4px;
                    // margin-right: 10px;
                    margin-right: 2%;

                    &--black {
                        background: #303030;
                    }

                    &--yellow {
                        background: #f2c94c;
                    }

                    &--red {
                        background: #eb5757;
                    }

                    &--blue {
                        background: #2f80ed;
                    }

                    &--green {
                        background: #219653;
                    }

                    &--all {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #f2f2f2;
                        margin-right: 0;
                        font-family: "Play";
                        font-size: 18px;
                        font-weight: 700;
                        color: #828282;
                    }
                }
            }
        }

        &__materials {
            width: 100%;
            height: 80px;
            // padding-left: 30px;
            padding-left: 8%;
            padding-top: 20px;
            border-top: 1px solid #f2f2f2;
        }

        &__size {
            width: 100%;
            height: 80px;
            // padding-left: 30px;
            padding-left: 8%;
            padding-top: 20px;
            border-top: 1px solid #f2f2f2;
        }
    }
}
