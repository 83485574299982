.basket {

  .container-internal {
    display: flex;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 1365px) {
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  &__products {
    max-width: calc(100% - 23% - 20px);
    width: 100%;
    @media screen and (max-width: 1365px) {
      max-width: calc(100% - 15%);
      margin-bottom: 20px;
    }
    @media screen and (max-width: 1200px) {
      max-width: 100%;
    }
    .container-internal {
      padding: 0 20px;
    }
    &__item {
      margin-bottom: 20px;

      &__title {
        position: relative;
        height: 115px;
        padding: 20px;
        background: #fbfbfb;
        border-bottom: 1px solid #e0e0e0;
        @media screen and (max-width: 576px) {
          // height: 95px;
          padding: 10px;
        }
        &__name {
					display: block;
					width: 90%;
          font-family: "Play";
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: $black;
          text-decoration: none;
          @media screen and (max-width: 576px) {
            font-size: 18px;
          }
          &:hover {
            color: $hover-color;
            cursor: pointer;
          }
        }

        &__category {
          margin-top: 10px;
          font-size: 14px;
          line-height: 18px;
          color: #bdbdbd;
          text-transform: uppercase;
        }

        &__delete {
          position: absolute;
          top: 30%;
          right: 20px;
          width: 30px;
          height: 30px;
          &:hover {
            cursor: pointer;
            &:after,
            &:before {
              border-color: #333;
            }
          }
          &:after {
            position: absolute;
            top: 15px;
            left: 5px;
            content: "";
            display: block;
            width: 20px;
            border-top: 2px solid #828282;
            transform: rotate(45deg);
          }
          &:before {
            position: absolute;
            top: 15px;
            left: 5px;
            content: "";
            display: block;
            width: 20px;
            border-top: 2px solid #828282;
            transform: rotate(-45deg);
          }
        }
      }

      &__info {
        display: flex;
        //height: 180px;
        background: #fff;
        @media screen and (max-width: 1024px) {
          flex-direction: column;
          height: 100%;
        }
        .title {
          font-size: 16px;
          line-height: 140%;
          color: #828282;
        }

        &__img {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 320px;
          width: 320px;
          border-right: 1px solid #e0e0e0;
          @media screen and (max-width: 1024px) {
            min-width: 100%;
            width: 100%;
            border-right: none;
            padding: 15px;
            border-bottom: 1px solid #e0e0e0;
          }
          img {
            @media screen and (max-width: 1024px) {
              max-width: 250px;
            }
          }
        }

        &__specifications {
          max-width: 260px;
          width: 100%;
          margin: 30px 0;
          padding-left: 40px;
          border-right: 1px solid #e0e0e0;
          @media screen and (max-width: 1600px) {
            padding-left: 20px;
          }
          @media screen and (max-width: 1200px) {
            padding-left: 15px;
          }
          @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: 100%;
            border-right: none;
            padding: 0;
            margin: 0;
          }
          &__color {
            @media screen and (max-width: 1024px) {
              display: flex;
              align-items: center;
              justify-content: space-between;

              max-width: 100%;
              width: 100%;
              padding: 15px;
              border-bottom: 1px solid #e0e0e0;
            }
            .title {
              margin-bottom: 10px;
              @media screen and (max-width: 1024px) {
                margin-bottom: 0;
              }
            }

            .color {
              width: 42px;
              height: 22px;
              border: 1px solid $hover-color;
            }
          }

          &__size {
            @media screen and (max-width: 1024px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
              max-width: 100%;
              width: 100%;
              padding: 15px;
              border-bottom: 1px solid #e0e0e0;
            }
            .title {
              margin-top: 15px;
              margin-bottom: 5px;
              @media screen and (max-width: 1024px) {
                margin: 0;
              }
            }

            .size {
              font-family: "Play";
              font-weight: 700;
              font-size: 18px;
              line-height: 140%;
              color: $black;
              @media screen and (max-width: 1600px) {
                font-size: 16px;
              }
            }
          }
        }

        &__price-one {
          max-width: 230px;
          width: 100%;
          margin: 30px 0;
          padding-left: 40px;
          border-right: 1px solid #e0e0e0;
          @media screen and (max-width: 1600px) {
            padding-left: 20px;
          }
          @media screen and (max-width: 1200px) {
            padding-left: 15px;
          }
          @media screen and (max-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 100%;
            width: 100%;
            border-right: none;
            padding: 15px;
            border-bottom: 1px solid #e0e0e0;
            margin: 0;
          }
          .prise {
            margin-top: 35px;
            font-family: "Play";
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;
            color: $black;
            @media screen and (max-width: 1600px) {
              font-size: 16px;
            }
            @media screen and (max-width: 1024px) {
              margin: 0;
            }
            &.discount {
              color: #eb5757;
              span {
                display: block;
              }
            }
            span {
              display: none;
              font-size: 16px;
              line-height: 140%;
              text-decoration: line-through;
              color: $black;
            }
          }
        }

        &__quantity {
          max-width: 230px;
          width: 100%;
          margin: 30px 0;
          padding-left: 40px;
          border-right: 1px solid #e0e0e0;
          @media screen and (max-width: 1600px) {
            padding-left: 20px;
          }
          @media screen and (max-width: 1200px) {
            padding-left: 15px;
          }
          @media screen and (max-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 100%;
            width: 100%;
            border-right: none;
            border-bottom: 1px solid #e0e0e0;
            padding: 15px;
            margin: 0;
          }
          .title {
            margin-bottom: 25px;
            @media screen and (max-width: 1024px) {
              margin: 0;
            }
          }

          .quantity {
            display: flex;
            &__minus {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 50px;
              border-left: 1px solid #e0e0e0;
              border-top: 1px solid #e0e0e0;
              border-bottom: 1px solid #e0e0e0;
              &:hover {
                background-color: #ffe8d3;
                cursor: pointer;
              }
              @media screen and (max-width: 1024px) {
                height: 40px;
              }
            }
            &__number {
              width: 50px;
              height: 50px;
              border: 1px solid #e0e0e0;
              font-weight: 700;
              font-size: 18px;
              line-height: 140%;
              text-align: center;
              color: $black;
              @media screen and (max-width: 1024px) {
                width: 45px;
                height: 40px;
              }
            }

            &__plus {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 50px;
              border-right: 1px solid #e0e0e0;
              border-top: 1px solid #e0e0e0;
              border-bottom: 1px solid #e0e0e0;
              &:hover {
                background-color: #ffe8d3;
                cursor: pointer;
              }
              @media screen and (max-width: 1024px) {
                height: 40px;
              }
            }
          }
        }

        &__cost {
          max-width: 230px;
          width: 100%;
          margin: 30px 0;
          padding-left: 40px;
          @media screen and (max-width: 1600px) {
            padding-left: 20px;
          }
          @media screen and (max-width: 1200px) {
            padding-left: 15px;
          }
          @media screen and (max-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 100%;
            width: 100%;
            border-right: none;
            border-bottom: 1px solid #e0e0e0;
            padding: 15px;
            margin: 0;
          }
          .title {
            margin-bottom: 40px;
            @media screen and (max-width: 1024px) {
              margin: 0;
            }
          }

          .cost {
            font-family: "Play";
            font-weight: 700;
            font-size: 27px;
            line-height: 140%;
            color: $black;
            @media screen and (max-width: 1600px) {
              font-size: 16px;
            }
          }
        }
      }
    }

    &__coupon {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 20px;
      max-width: 433px;
      width: 100%;
      height: 84px;
      margin-left: auto;
      background-color: #fff;
      @media screen and (max-width: 1200px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 576px) {
        height: 100%;
        flex-direction: column;
      }
      input {
        max-width: 222px;
        width: 100%;
        height: 44px;
        padding: 12px;
        border: 1px solid #bdbdbd;
        font-size: 16px;
        line-height: 140%;
        color: $black;
        &::placeholder {
          color: #bdbdbd;
        }
        @media screen and (max-width: 576px) {
          max-width: 100%;
          margin-bottom: 10px;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 160px;
        width: 100%;
        border: none;
        height: 44px;
        background: #bdbdbd;
        font-size: 16px;
        line-height: 140%;
        color: #fff;
        &:hover {
          background: #988080;
        }
        @media screen and (max-width: 576px) {
          max-width: 100%;
        }
      }
    }
  }

  &__sum-info {
    position: sticky;
    top: 90px;
    max-width: calc(100% - 77%);
    width: 100%;
    height: 270px;
    margin-top: 20px;
    @media screen and (max-width: 1365px) {
      max-width: 584px;
      margin: 0 auto;
    }
    &__price-oll {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      background-color: #fff;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: $black;
      border-bottom: 1px solid #e8e8e8;
      @media screen and (max-width: 767px) {
        font-size: 16px;
        padding: 10px;
      }
    }

    &__quantity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      background-color: #fff;
      border-bottom: 1px solid #e8e8e8;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $black;
      @media screen and (max-width: 767px) {
        padding: 10px;
      }
    }

    &__discount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      background-color: #fff;
      border-bottom: 1px solid #e8e8e8;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $discount-color;
      @media screen and (max-width: 767px) {
        padding: 10px;
      }
    }

    &__total {
      background-color: #fbfbfb;
      &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        font-family: "Play";
        line-height: 140%;
        @media screen and (max-width: 767px) {
          padding: 15px;
        }
        &__title {
          font-size: 18px;
          font-weight: 600;
        }

        &__price {
          font-weight: 700;
          font-size: 24px;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
      }

      &__button {
        border: none;
        width: 100%;
        padding: 0;
        a {
          display: flex;
          align-items: center;
          justify-content: center;

          height: 48px;
          background-color: $hover-color;

          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: #fff;
          &:hover {
            background-color: #e0893c;
          }
        }
      }
    }
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
