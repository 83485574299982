.stocks-page {
    .special-offer {
        margin: 0;
        margin-bottom: 20px;
        min-height: 354px;
        &__wrap {
            &__slide {
                background-repeat: no-repeat;
                @media screen and (max-width: 767px) {
                    align-items: flex-start;
                    background-repeat: no-repeat;
                    background-position: bottom center;
                    // background-image: url("./../uploads/slider-banner-mob.jpg") !important;
                }
                .stock-img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media screen and (max-width: 1024px) {
											background-position-x: -70px;
										}
                    @media screen and (max-width: 767px) {
                        &.desktop {
                            display: none;
                        }
                        &.mob {
														display: block !important;
														background-position: bottom center;
														background-size: auto;
                        }
                    }
                    &.mob {
                        display: none;
                    }
                }
                &__content {
                    margin-bottom: 100px;
                    z-index: 2;
                }
            }
        }
    }
    .stocks {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        &__stock {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 25%;
            width: calc(33.33% - 15px);
            margin-right: 15px;
            margin-bottom: 15px;
            @media screen and (max-width: 1365px) {
                width: calc(50% - 15px);
            }
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-right: 0;
            }
            &__img {
                position: relative;
                background-color: #fff;
                img {
                    width: 100%;
                }
                &__plus {
                    position: absolute;
                    width: 30%;
                    top: 10px;
                    right: 15px;
                }
            }
            &__date {
                background-color: rgba(225, 226, 236, 0.65);
                padding-left: 25px;
                font-size: 16px;
                line-height: 22px;
                @media screen and (max-width: 576px) {
                    font-size: 14px;
                    line-height: 19px;
                }
            }
            &__info {
                background-color: #fff;
                padding-left: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
                &__title {
                    font-family: "Play";
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 23px;
                    @media screen and (max-width: 576px) {
                        font-size: 16px;
                    }
                }
                &__text {
                    font-size: 18px;
                    line-height: 25px;
                    @media screen and (max-width: 576px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
