.checkout {
    .container-internal {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1365px) {
            flex-direction: column;
        }
        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }
    &__contact-info {
        max-width: calc(100% - 33% - 20px);
        width: 100%;
        @media screen and (max-width: 1365px) {
            max-width: calc(100% - 15%);
            margin-bottom: 20px;
        }
        @media screen and (max-width: 1024px) {
            max-width: 100%;
        }
        .container-internal {
            padding: 0 20px;
        }
        label {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 22px;
            color: $black;
            @media screen and (max-width: 767px) {
                margin-bottom: 4px;
            }
        }
        input {
            height: 50px;
            padding: 14px;
            border: 2px solid #bdbdbd;
            font-size: 16px;
            line-height: 22px;
            @media screen and (max-width: 767px) {
                height: 40px;
                padding: 10px;
            }
        }
        .jq-selectbox {
            &.dropdown {
                .jq-selectbox__trigger-arrow {
                    transform: rotate(0deg) !important;
                }
            }
            .jq-selectbox__select {
                display: flex;
                align-items: center;
                height: 50px;
                border: 2px solid #bdbdbd;
                border-radius: 0;
                box-shadow: none;
                background: #fff;
                padding: 0 14px;
                .jq-selectbox__select-text {
                    font-size: 16px;
                    line-height: 22px;
                    color: #828282;
                }
                .jq-selectbox__trigger {
                    border: none;
                    .jq-selectbox__trigger-arrow {
                        top: 18px;
                        right: 10px;
                        border-top: 7px solid #999;
                        border-right: 7px solid transparent;
                        border-left: 7px solid transparent;
                        transform: rotate(-90deg);
                        transition: 0.2s;
                    }
                }
            }
            .jq-selectbox__dropdown {
                width: 100%;
                border-radius: 0;
                border: none;
                box-shadow: none;
                background: #fff;
                ul {
                    max-width: 100%;
                    max-height: 240px !important;
                    li {
                        font-size: 14px;
                        &.selected {
                            background-color: $hover-color;
                        }
                    }
                }
            }
        }
        &__main {
            margin-bottom: 20px;
            padding: 50px;
            background: #ffffff;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                padding: 15px;
            }
            &__name {
                display: flex;
                flex-direction: column;
                width: calc(50% - 10px);
                margin-bottom: 20px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
            &__surname {
                display: flex;
                flex-direction: column;
                width: calc(50% - 10px);
                margin-bottom: 20px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
            &__phone {
                display: flex;
                flex-direction: column;
                width: calc(50% - 10px);
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
            &__mail {
                display: flex;
                flex-direction: column;
                width: calc(50% - 10px);
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        &__place {
            margin-bottom: 20px;
            padding: 50px;
            background: #ffffff;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                padding: 15px;
            }
            &__country {
                display: flex;
                flex-direction: column;
                width: calc(33.33% - 10px);
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
            &__region {
                display: flex;
                flex-direction: column;
                width: calc(33.33% - 10px);
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
            &__city {
                display: flex;
                flex-direction: column;
                width: calc(33.33% - 10px);
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
                .jq-selectbox {
                    display: none;
                    &.is-active {
                        display: block;
                    }
                }
            }
            &__address {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 20px;
                @media screen and (max-width: 767px) {
                    margin-top: 0;
                }
            }
        }
        &__comment {
            display: flex;
            flex-direction: column;
            padding: 50px;
            background: #ffffff;
            @media screen and (max-width: 767px) {
                padding: 15px;
            }
            textarea {
                height: 213px;
                padding: 15px;
                border: 2px solid #bdbdbd;
                resize: none;
                overflow: auto;
            }
        }
    }
    &__product-info {
        max-width: calc(100% - 67%);
        width: 100%;
        margin-top: 20px;
        @media screen and (max-width: 1365px) {
            max-width: 584px;
            margin: 0 auto;
        }
        &__products {
            background: #fff;
            margin-bottom: 20px;
            &__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 30px;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: $black;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 767px) {
                    padding: 10px;
                    font-size: 14px;
                }
                &__item {
                    width: 75%;
                    @media screen and (max-width: 767px) {
                        width: 65%;
                    }
                }
                &__price {
                    width: 25%;
                    text-align: right;
                    @media screen and (max-width: 767px) {
                        width: 35%;
                    }
                }
            }
            &__items {
                display: flex;
                align-items: center;
                padding: 20px 30px;
                font-size: 16px;
                line-height: 140%;
                color: $black;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 767px) {
                    padding: 10px;
                }
                &__item {
                    width: 75%;
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        width: 65%;
                    }
                    span {
                        font-weight: 700;
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                }
                &__price {
                    width: 25%;
                    font-weight: 600;
                    text-align: right;
                    @media screen and (max-width: 767px) {
                        width: 35%;
                    }
                }
            }
            &__total-info {
                &__sum {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 30px;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 140%;
                    color: $black;
                    @media screen and (max-width: 767px) {
                        padding: 10px;
                        font-size: 16px;
                    }
                    &__title {
                        width: 75%;
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                            width: 65%;
                        }
                    }
                    &__price {
                        width: 25%;
                        font-weight: 700;
                        text-align: right;
                        @media screen and (max-width: 767px) {
                            width: 35%;
                        }
                    }
                }
                &__discount {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 30px;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 140%;
                    color: $discount-color;
                    @media screen and (max-width: 767px) {
                        padding: 10px;
                        font-size: 16px;
                    }
                    &__title {
                        width: 75%;
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                            width: 65%;
                        }
                    }
                    &__price {
                        width: 25%;
                        font-weight: 700;
                        text-align: right;
                        @media screen and (max-width: 767px) {
                            width: 35%;
                        }
                    }
                }
                &__delivery {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 30px;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 140%;
                    @media screen and (max-width: 767px) {
                        padding: 10px;
                        font-size: 16px;
                    }
                    &__title {
                        width: 75%;
                        text-transform: uppercase;
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            width: 65%;
                        }
                    }
                    &__price {
                        width: 25%;
                        font-weight: 700;
                        text-align: right;
                        @media screen and (max-width: 767px) {
                            width: 35%;
                        }
                    }
                }
            }
            &__total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 30px;
                font-family: "Play";
                font-weight: 700;
                font-size: 24px;
                line-height: 140%;
                color: $black;
                border-top: 1px solid #bdbdbd;
                @media screen and (max-width: 767px) {
                    padding: 10px;
                    font-size: 18px;
                }
                &__title {
                    width: 50%;
                    text-transform: uppercase;
                }
                &__price {
                    width: 50%;
                    text-align: right;
                }
            }
        }
        &__delivery {
            background: #fff;
            margin-bottom: 20px;
            &__title {
                padding: 20px 30px;
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: $black;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 767px) {
                    padding: 10px;
                }
            }
            &__way {
                display: flex;
                align-items: center;
                padding: 20px 30px;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 767px) {
                    padding: 15px 20px;
                }
                &__title {
                    margin-left: 15px;
                    font-size: 16px;
                    line-height: 140%;
                    color: $black;
                    @media screen and (max-width: 767px) {
                        font-size: 16px;
                        margin-left: 7px;
                    }
                }
            }
        }
        &__payment {
            background: #fff;
            &__title {
                padding: 20px 30px;
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: $black;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 767px) {
                    padding: 10px;
                }
            }
            &__way {
                display: flex;
                align-items: center;
                padding: 20px 30px;
                border-bottom: 1px solid #e8e8e8;
                @media screen and (max-width: 767px) {
                    padding: 15px 20px;
                }
                &__title {
                    margin-left: 15px;
                    font-size: 16px;
                    line-height: 140%;
                    color: $black;
                    @media screen and (max-width: 767px) {
                        font-size: 16px;
                        margin-left: 7px;
                    }
                }
            }
        }
        &__submit {
            width: 100%;
            height: 70px;
            background-color: $hover-color;
            border: none;
            color: #fff;
            font-size: 18px;
            line-height: 140%;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 70px;
                text-decoration: none;
                @media screen and (max-width: 767px) {
                    height: 60px;
                }
            }
            &:hover {
                background-color: #e0893c;
                cursor: pointer;
            }
            @media screen and (max-width: 767px) {
                height: 60px;
            }
        }
        .control {
            display: block;
            position: relative;
        }
        .control input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        .control_indicator {
            display: block;
            position: relative;
            height: 20px;
            width: 20px;
            border: 1px solid #bdbdbd;
            border-radius: 50%;
        }
        .control:hover input~.control_indicator {
            background: #cccccc;
        }
        .control input:checked~.control_indicator {
            background: $hover-color;
            border: none;
        }
        .control_indicator:after {
            box-sizing: unset;
            content: "";
            position: absolute;
            display: none;
        }
        .control input:checked~.control_indicator:after {
            display: block;
        }
        .control-radio .control_indicator {
            border-radius: 50%;
        }
        .control-radio .control_indicator:after {
            left: 5px;
            top: 5px;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: #ffffff;
        }
    }
}
