main {
    // overflow-x: hidden;
    .banner {
        margin-bottom: 2px;

        img {
            // max-width: 1563px;
            width: 100%;
            max-height: 582px;
            height: 100%;
            object-fit: cover;
            object-position: center;

            &:nth-child(2) {
                display: none;
            }

            @media screen and (max-width: 1024px){
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    display: block;
                }
            }
        }
    }

    .main-slider {
        &__content {
            display: flex;
            height: 270px;
            background: #3c415e;
            @media screen and (max-width: 767px) {
                height: 100%;
            }

            &__left {
                position: relative;
                max-width: 1060px;
                width: 100%;

                &__wrap {
                    //   max-width: 970px;
                    width: 100%;

                    &__slide {
                        // max-width: 950px;
                        width: 100%;
                        height: auto;
                        margin-top: 60px;
                        padding-left: 20px;
                        padding-right: 100px;
                        //&:before {
                        //    content: attr(data-number);
                        //    position: absolute;
                        //    left: -5px;
                        //    bottom: -20px;
                        //    z-index: 1;
                        //    font-family: "Play";
                        //    font-size: 162px;
                        //    color: #3c415e;
                        //    font-weight: 700;
                        //    text-shadow: #4f5267 1px 0, #4f5267 -1px -1px 0, #4f5267 -1px 1px 0, #4f5267 1px -1px 0;
                        //}
                        @media screen and (max-width: 767px) {
                            margin-top: 30px;
                            margin-bottom: 75px;
                            padding-left: 14px;
                            padding-right: 14px;
                        }

                        h2 {
                            font-family: "Play";
                            font-size: 27px;
                            color: #fff;
                            font-weight: 700;
                            z-index: 10;
                            padding: 1rem 0;
                            margin: 0;

                            &:after {
                                display: none;
                            }

                            @media screen and (max-width: 767px) {
                                font-size: 24px;
                                padding: 0;
                            }
                        }

                        p {
                            position: relative;
                            z-index: 10;
                            display: inline-block;
                            max-width: 910px;
                            width: 100%;
                            font-family: "Play";
                            font-size: 16px;
                            line-height: 150%;
                            color: #e9eaff;
                            font-weight: 400;
                            padding: 0;
                            @media screen and (max-width: 767px) {
                                padding-top: 10px;
                            }
                        }

                        a {
                            position: relative;
                            max-width: 910px;
                            width: 100%;
                            display: block;
                            margin-top: 20px;
                            padding-right: 30px;
                            text-align: right;
                            font-size: 16px;
                            color: #fff;
                            font-weight: 400;
                            text-decoration: none;
                            z-index: 10;

                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 40%;
                                z-index: 2;
                                width: 110px;
                                border-top: 2px solid #f2994a;
                                @media screen and (max-width: 767px) {
                                    // display: none;
                                    width: 51px;
                                }
                            }

                            &:before {
                                position: absolute;
                                top: 6px;
                                right: 0;
                                content: "";
                                display: block;
                                width: 8px;
                                height: 8px;
                                border-top: 2px solid #fff;
                                border-left: 2px solid #fff;
                                transform: rotate(135deg);
                            }

                            @media screen and (max-width: 767px) {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
            }

            &__right {
                max-width: 520px;
                width: 100%;
                @media screen and (max-width: 1024px) {
                    display: none;
                }

                &__wrap {
                    &__slide {
                        img {
                            // height: 270px;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
            }
        }

        &--pagination {
            width: auto;
            left: auto;
            right: 25px;
            font-family: "Play";
            font-size: 18px;
            color: #fff;
            font-weight: 700;

            .swiper-pagination-current {
                font-size: 36px;
            }
        }

        &--prev {
            top: 162px;
            // top: 22px;
            width: 90px;
            height: 130px;
            right: 0;
            left: auto;
            background: #ffffff;
            opacity: 1 !important;
            color: $normal-color;

            &:hover {
                background: #e4dbdb;
            }

            @media screen and (max-width: 767px) {
                top: auto;
                bottom: 0;
                left: 0;
                right: auto;
                width: 50%;
                height: 65px;
                border-right: 2px solid #3c415e;
            }

            &:after {
                font-size: 25px;
                font-weight: bold;
            }
        }

        &--next {
            top: 22px;
            // top: 162px;
            width: 90px;
            height: 130px;
            right: 0;
            background: #ffffff;
            opacity: 1 !important;
            color: $normal-color;

            &:hover {
                background: #e4dbdb;
            }

            @media screen and (max-width: 767px) {
                top: auto;
                bottom: 0;
                width: 50%;
                height: 65px;
                border-left: 2px solid #3c415e;
            }

            &:after {
                font-size: 25px;
                font-weight: bold;
            }
        }
    }

    .popular {
        h2 {
            margin-top: 100px;
            @media screen and (max-width: 1024px) {
                margin-top: 50px;
            }
        }

        .products__container {
            @media screen and (max-width: 767px) {
                padding-bottom: 60px;
            }

            &__wrapper {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                @media screen and (max-width: 1024px) {
                    display: flex;
                    flex-wrap: nowrap;
                }

                &__slide {
                    //width: 25%;
                    //max-height: 400px;
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                        max-height: unset;
                        height: 100%;
                    }

                    .product-item {
                        width: 100%;
                        height: 100%;

                        img {
                            object-fit: cover;
                            max-width: 100%;
                        }
                    }
                }
            }

            .swiper-pagination {
                display: none;
                color: $normal-color;
                @media screen and (max-width: 767px) {
                    display: block;
                    width: 80%;
                }
                width: 100%;
                left: auto;
            }

            .swiper-button-prev {
                display: none;
                width: 43px;
                height: 43px;
                background: $hover-color;
                border-radius: 50%;
                opacity: 1 !important;
                color: #fff;

                &:hover {
                    background: #e0893c;
                }

                &.swiper-button-disabled {
                    border: 2px solid $hover-color;
                    background-color: #fff;
                    color: $hover-color;

                    &:after {
                        border-top: 3px solid $hover-color;
                        border-right: 3px solid $hover-color;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 39%;
                    left: 41%;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(-135deg);
                }

                @media screen and (max-width: 1024px) {
                    display: flex;
                }
                @media screen and (max-width: 767px) {
                    top: auto;
                    bottom: 0;
                    .swiper-button-prev.swiper-button-disabled {
                        background-color: #f2f2f2;
                    }
                }
            }

            .swiper-button-next {
                display: none;
                width: 43px;
                height: 43px;
                background: $hover-color;
                border-radius: 50%;
                opacity: 1 !important;
                color: #fff;

                &:hover {
                    background: #e0893c;
                }

                &.swiper-button-disabled {
                    border: 2px solid $hover-color;
                    background-color: #fff;
                    color: $hover-color;

                    &:after {
                        border-top: 3px solid $hover-color;
                        border-right: 3px solid $hover-color;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 39%;
                    left: 39%;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(45deg);
                }

                @media screen and (max-width: 1024px) {
                    display: flex;
                }
                @media screen and (max-width: 767px) {
                    top: auto;
                    bottom: 0;
                    .swiper-button-prev.swiper-button-disabled {
                        background-color: #f2f2f2;
                        border-top: 3px solid #fff;
                        border-right: 3px solid #fff;
                    }
                }
            }
        }
    }

    .special-offer {
        margin-top: 100px;
        margin-bottom: 100px;
        // max-width: 100%;
        width: 100%;
        height: 300px;
        @media screen and (max-width: 767px) {
            height: 580px;
        }
        @media screen and (max-width: 1024px) {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        &__wrap {
            &__slide {
                position: relative;
                display: flex;
                align-items: center;
                text-decoration: none;
                background-size: contain;
                @media screen and (max-width: 1024px) {
                    background-position-x: -60px;
                }
                @media screen and (max-width: 767px) {
                    align-items: flex-start;
                    background-repeat: no-repeat;
                    background-position: bottom center;
                    // background-image: url("./../uploads/slider-banner-mob.jpg") !important;
                }

                .stock-img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media screen and (max-width: 767px) {
                        &.desktop {
                            display: none;
                        }
                        &.mob {
                            display: block !important;
                            background-position: bottom center;
                            background-size: auto;
                        }
                    }

                    &.mob {
                        display: none;
                    }
                }

                //&:after {
                //    content: attr(data-number);
                //    position: absolute;
                //    left: 5px;
                //    bottom: 0;
                //    font-family: "Play";
                //    font-size: 141px;
                //    font-weight: 700;
                //    color: rgba(225, 225, 225, 0);
                //    -webkit-text-stroke: 1px #fff;
                //    z-index: 10;
                //    @media screen and (max-width: 767px) {
                //        display: none;
                //    }
                //}

                &__content {
                    position: relative;
                    max-width: 800px;
                    width: 100%;
                    margin-left: 500px;
                    padding-bottom: 40px;
                    @media screen and (max-width: 1024px) {
                        margin-left: 370px;
                        padding-bottom: 40px;
                    }
                    @media screen and (max-width: 767px) {
                        margin: 0 auto;
                        padding: 20px 10px 0;
                        width: 280px;
                    }

                    h2 {
                        height: auto;
                        color: $normal-color;
                        margin: 0;
                        padding: 1rem 0;

                        &:after {
                            display: none;
                        }

                        @media screen and (max-width: 1024px) {
                            font-size: 24px;
                        }
                    }

                    p {
                        padding: 0;
                        font-size: 16px;
                        padding-right: 10px;
                        line-height: 140%;
                        @media screen and (max-width: 1024px) {
                            // font-size: 1rem;
                            font-size: 14px;
                            padding-right: 0;
                            line-height: 140%;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                            line-height: 170%;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }

        .swiper-pagination {
            width: auto;
            left: 500px;
            bottom: 20px;
            font-family: "Play";
            font-size: 18px;
            color: #fff;
            font-weight: 700;
            // &:after {
            //   content: "";
            //   position: absolute;
            //   top: -5px;
            //   left: 0;
            //   width: 110px;
            //   border-top: 2px solid #f2994a;
            // }
            @media screen and (max-width: 1024px) {
                width: 100%;
                left: auto;
                // &:after {
                //   left: 45%;
                // }
            }
            @media screen and (max-width: 767px) {
                width: auto;
                left: 20px;
                // &:after {
                //   left: 0;
                // }
            }

            .swiper-pagination-current {
                font-size: 36px;
            }
        }

        .swiper-button-next {
            top: 260px;
            // top: 162px;
            width: 43px;
            height: 43px;
            right: 20px;
            background: #ffffff;
            border-radius: 50%;
            opacity: 1 !important;
            color: $normal-color;

            &:hover {
                background: #e4dbdb;
            }

            @media screen and (max-width: 767px) {
                top: auto;
                bottom: 20px;
            }

            &.swiper-button-disabled {
                border: 2px solid #ffffff;
                background: rgba(225, 225, 225, 0);
                color: #fff;

                &:after {
                    border-top: 3px solid #ffffff;
                    border-right: 3px solid #ffffff;
                }
            }

            &:after {
                content: "";
                position: absolute;
                top: 39%;
                left: 41%;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 3px solid $normal-color;
                border-right: 3px solid $normal-color;
                transform: rotate(45deg);
            }
        }

        .swiper-button-prev {
            top: 260px;
            // top: 22px;
            width: 43px;
            height: 43px;
            right: 75px;
            left: auto;
            background: #ffffff;
            border-radius: 50%;
            opacity: 1 !important;
            color: $normal-color;

            &:hover {
                background: #e4dbdb;
            }

            &.swiper-button-disabled {
                border: 2px solid #ffffff;
                background: rgba(225, 225, 225, 0);
                color: #fff;

                &:after {
                    border-top: 3px solid #ffffff;
                    border-right: 3px solid #ffffff;
                }
            }

            @media screen and (max-width: 767px) {
                top: auto;
                bottom: 20px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 39%;
                left: 41%;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 3px solid $normal-color;
                border-right: 3px solid $normal-color;
                transform: rotate(-135deg);
            }
        }
    }

    .super-offer {
        .products__container {
            width: 100%;
            @media screen and (max-width: 767px) {
                padding-bottom: 60px;
            }

            &__wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                @media screen and (max-width: 1024px) {
                    flex-wrap: nowrap;
                }

                &__slide {
                    display: flex;
                    width: 25%;
                    @media screen and (max-width: 1024px) {
                        height: 100%;
                    }

                    .product-item {
                        width: 100%;
                        &__img {
                            //max-width: 80%;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .swiper-pagination {
                display: none;
                color: $normal-color;
                @media screen and (max-width: 767px) {
                    display: block;
                    width: 80%;
                }
                width: 100%;
                left: auto;
            }

            .swiper-button-prev {
                display: none;
                width: 43px;
                height: 43px;
                background: $hover-color;
                border-radius: 50%;
                opacity: 1 !important;
                color: #fff;

                &:hover {
                    background: #e0893c;
                }

                &.swiper-button-disabled {
                    border: 2px solid $hover-color;
                    background-color: #fff;
                    color: $hover-color;

                    &:after {
                        border-top: 3px solid $hover-color;
                        border-right: 3px solid $hover-color;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 39%;
                    left: 41%;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(-135deg);
                }

                @media screen and (max-width: 1024px) {
                    display: flex;
                }
                @media screen and (max-width: 767px) {
                    top: auto;
                    bottom: 0;
                    .swiper-button-prev.swiper-button-disabled {
                        background-color: #f2f2f2;
                    }
                }
            }

            .swiper-button-next {
                display: none;
                width: 43px;
                height: 43px;
                background: $hover-color;
                border-radius: 50%;
                opacity: 1 !important;
                color: #fff;

                &:hover {
                    background: #e0893c;
                }

                &.swiper-button-disabled {
                    border: 2px solid $hover-color;
                    background-color: #fff;
                    color: $hover-color;

                    &:after {
                        border-top: 3px solid $hover-color;
                        border-right: 3px solid $hover-color;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 39%;
                    left: 39%;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-top: 3px solid #fff;
                    border-right: 3px solid #fff;
                    transform: rotate(45deg);
                }

                @media screen and (max-width: 1024px) {
                    display: flex;
                }
                @media screen and (max-width: 767px) {
                    top: auto;
                    bottom: 0;
                    .swiper-button-prev.swiper-button-disabled {
                        background-color: #f2f2f2;
                        border-top: 3px solid #fff;
                        border-right: 3px solid #fff;
                    }
                }
            }
        }

        &__see-all {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            margin-top: 10px;
            background: #ffffff;

            a {
                position: relative;
                padding-right: 65px;
                text-decoration: none;
                font-family: "Play";
                font-size: 16px;
                font-weight: 700;
                color: $normal-color;

                &:hover {
                    color: $hover-color;
                    cursor: pointer;

                    &:after {
                        border: 1px solid $hover-color;
                    }

                    &:before {
                        border-top: 2px solid $hover-color;
                        border-left: 2px solid $hover-color;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 8px;
                    right: 0;
                    width: 45px;
                    border: 1px solid $normal-color;
                    z-index: 1;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    right: 0;
                    width: 10px;
                    height: 10px;
                    border-top: 2px solid $normal-color;
                    border-left: 2px solid $normal-color;
                    transform: rotate(135deg);
                    z-index: 2;
                }
            }
        }
    }

    .text-info {
        margin-top: 100px;
        margin-bottom: 100px;
        @media screen and (max-width: 767px) {
            margin: 30px 0;
        }

        &.is-active {
            .text-info__text-wrap {
                max-height: 100%;
                height: 100%;
                overflow-y: inherit;
            }

            .text-info__watch-more {
                &:after {
                    top: 4px;
                    transform: rotate(45deg);
                }

                &:before {
                    content: attr(data-hidden);
                }
            }
        }

        &__text-wrap {
            // min-height: 400px;
            @media screen and (max-width: 1024px) {
                &.small {
                    height: 100%;
                }
                margin-top: 50px;
                margin-bottom: 50px;
                // max-height: 400px;
                height: 300px;
                overflow-y: hidden;
                transition: 0.2s;
            }

            p {
                padding: 0;
                margin: 0;
                color: $text-color;
                font-size: 16px;
                line-height: 150%;
            }

            ul {
                li {
                    font-size: 16px;
                    line-height: 150%;
                    color: $text-color;
                    // list-style: none;
                }
            }

            ol {
                li {
                    font-size: 16px;
                    line-height: 150%;
                    color: $text-color;
                }
            }
        }

        &__watch-more {
            display: none;
            position: relative;
            text-align: right;
            padding-right: 15px;
            font-size: 16px;
            color: $normal-color;

            &:hover {
                cursor: pointer;
                color: $hover-color;

                &:after {
                    border-top: 2px solid $hover-color;
                    border-left: 2px solid $hover-color;
                }
            }

            &.none {
                display: none;
            }

            &:before {
                content: attr(data-text);
            }

            &:after {
                position: absolute;
                top: 6px;
                right: 0;
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-top: 2px solid $normal-color;
                border-left: 2px solid $normal-color;
                transform: rotate(135deg);
            }

            @media screen and (max-width: 1024px) {
                display: block;
            }
        }
    }
}

.products__container {
    z-index: 10;
}
