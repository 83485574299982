.furniture-catalog-page {
    margin-top: 20px;
    &__wrap {
        padding: 38px 30px;
        background-color: #fff;
        @media screen and (max-width: 767px) {
            padding: 25px 10px;
        }
    }
    .furniture-catalog {
        &__nav {
            display: flex;
            flex-direction: column;
            .link {
                display: flex;
                align-items: center;
                width: max-content;
                margin-bottom: 20px;
                text-decoration: none;
                font-family: "Play";
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $black;
                &:hover {
                    color: $hover-color;
                    span {
                        &:before {
                            border-color: $hover-color;
                        }
                        &:after {
                            border-color: $hover-color;
                        }
                    }
                }
                span {
                    position: relative;
                    display: block;
                    width: 26px;
                    margin-left: 20px;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 26px;
                        border: 1px solid $black;
                        z-index: 1;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: -4px;
                        right: 0;
                        width: 10px;
                        height: 10px;
                        border-top: 2px solid $black;
                        border-left: 2px solid $black;
                        transform: rotate(135deg);
                        z-index: 2;
                    }
                }
            }
        }
    }
    .furniture-categories {
        margin-bottom: 35px;
        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        &__title {
            margin-bottom: 20px;
            font-family: "Play";
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $black;
            @media screen and (max-width: 767px) {
                font-size: 20px;
            }
        }
        &__wrap {
            //   display: flex;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            grid-gap: 60px;
            padding-bottom: 20px;
            border-bottom: 1px solid #bdbdbd;
            @media screen and (max-width: 767px) {
                grid-gap: 10px;
            }
            .category {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // margin-right: 60px;
                margin-bottom: 15px;
                text-decoration: none;
                // &:hover {
                //     .category__img {
                //         img {
                //             height: 100%;
                //             display: none;
                //             &.img__hov {
                //                 display: block;
                //             }
                //         }
                //     }
                // }
                &__img {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    width: 80px;
                    height: 80px;
                    margin-bottom: 10px;
                    svg {
                        width: auto;
                        height: 100%;
                        display: block;
                        object-fit: contain;
                        object-position: bottom;
                        &.img__hov {
                            display: none;
                        }
                    }
                }
                &__name {
                    max-width: 150px;
                    width: 100%;
                    font-size: 16px;
                    line-height: 22px;
                    color: $black;
                }
            }
        }
    }
}
