/*
PROJECT CONFIG
 */

// CUSTOM CONFIG
@import "main-config";
// include main variables

// FUNCTIONS
@import "_reset/fonts";
// fonts loop
@import "_functions/placeholder";
// change placeholder on focus
@import "_functions/rem";
// px to rem

// RESET
@import "_reset/base";
// reset styles
@import "_reset/view-wrapper";
// .view-wrapper, .container, .row
@import "_reset/default-text";
// default classes (text-align and bold) and default styles for text (H1-H6, P, A, UL/LI/OL)
@import "_reset/view-text";
// classes and styles for block .view-text
@import "_reset/clearfix";
// clearfix class

// LIBRARIES
@import "~@fortawesome/fontawesome-free/css/all.css";
