.about-us-page {
    &__info {
        margin-top: 20px;
        padding: 70px;
        background-color: #fff;
        @media screen and (max-width: 767px) {
            padding: 15px;
        }
        h2 {
            margin: 0;
            margin-bottom: 50px;
            padding: 0;
            @media screen and (max-width: 767px) {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        &__text {
            p {
                padding: 0;
                font-size: 16px;
                line-height: 150%;
                padding-bottom: 30px;
                @media screen and (max-width: 767px) {
                    padding-bottom: 10px;
                }
                // span {
                //     font-weight: 700;
                // }
            }
            ul {
                li {
                    list-style: disc;
                    font-family: "Open Sans";
                    font-size: 16px;
                    line-height: 150%;
                    color: rgb(51, 51, 51);
                }
						}
						ol {
							li {
									list-style: decimal;
									font-family: "Open Sans";
									font-size: 16px;
									line-height: 150%;
									color: rgb(51, 51, 51);
							}
					}
        }
    }
    &__advantages {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 20px;
        padding: 50px;
        background-color: #fff;
        @media screen and (max-width: 767px) {
            padding: 15px;
        }
        h2 {
            margin: 0;
            margin-bottom: 50px;
            // padding: 0;
            @media screen and (max-width: 767px) {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        .advantage {
            display: flex;
            align-items: center;
            max-width: 318px;
            width: 100%;
            height: 105px;
            padding: 0 25px;
            border: 5px solid #f2f2f2;
            margin-bottom: 15px;
            margin-right: 15px;
            @media screen and (max-width: 767px) {
                padding: 0 10px;
                margin-right: 0;
                height: 80px;
            }
            &__img {
                margin-right: 20px;
                @media screen and (max-width: 767px) {
                    margin-right: 10px;
                }
                img {
                    @media screen and (max-width: 767px) {
                        width: 40px;
                    }
                }
            }
            &__title {
                max-width: 190px;
                width: 100%;
                font-weight: 700;
                font-size: 18px;
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
    }
}
